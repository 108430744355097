import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":"","max-width":"800"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_vm._v(" "+_vm._s(_vm.$t('page.contenttype.regex_examples'))+" "),_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.closeDialog}},[_c(VIcon,[_vm._v(_vm._s(_vm.mdiClose))])],1)],1),_c(VDivider),_c(VCardText,[_c('p',[_vm._v(_vm._s(_vm.$t('page.contenttype.regex_example_info')))]),_c(VList,[_c(VListItem,{on:{"click":function($event){_vm.selectRegexPattern('/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,})$/i', 'page.contenttype.regex_email')}}},[_vm._v(" "+_vm._s(_vm.$t('page.contenttype.regex_email'))),_c('br'),_c('code',[_vm._v("/^[_a-z0-9-]+(\\.[_a-z0-9-]+)*@[a-z0-9-]+(\\.[a-z0-9-]+)*(\\.[a-z]{2,})$/i")])]),_c(VListItem,{on:{"click":function($event){return _vm.selectRegexPattern('/^.{0,100}$/', 'page.contenttype.regex_maxlenght_100')}}},[_c('div',[_vm._v(_vm._s(_vm.$t('page.contenttype.regex_maxlenght_100')))]),_c('code',[_vm._v("/^.{0,100}$/")])]),_c(VListItem,{on:{"click":function($event){return _vm.selectRegexPattern('/^.{3,}$/', 'page.contenttype.regex_minlenght_3')}}},[_c('div',[_vm._v(_vm._s(_vm.$t('page.contenttype.regex_minlenght_3')))]),_c('code',[_vm._v("/^.{3,}$/")])]),_c(VListItem,{on:{"click":function($event){return _vm.selectRegexPattern('/^[0-9]{4} [A-Z]{2}$/', 'page.contenttype.regex_zipcode_nl')}}},[_vm._v(" "+_vm._s(_vm.$t('page.contenttype.regex_zipcode_nl'))),_c('br'),_c('code',[_vm._v("/^[0-9]{4} [A-Z]{2}$/")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }