<template>
  <div>
    <!-- Create button -->

    <v-divider />
    <v-row align="center" justify="space-between" class="pa-4">
      <v-col cols="12" sm="auto">
        <v-row align="center" justify="space-between">
          <v-col cols="12" sm="auto">
            <v-badge overlap bordered :value="active_filter_count > 0" :content="active_filter_count">
              <v-btn small block color="primary" @click="filter_drawer = !filter_drawer">
                <v-icon>{{ mdiFilterOutline }}</v-icon>
                {{ $t('table.filter') }}
              </v-btn>
            </v-badge>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-btn small color="primary" @click="exportData">
              <v-icon>{{ mdiDownload }}</v-icon>
              {{ $t('table.export') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="auto">
            <v-text-field
              v-model="simple_search_model"
              hide-details="auto"
              class="my-0 py-0"
              @keypress.enter="simpleSearchOnClick"
            >
              <template slot="append">
                <v-icon @click="simpleSearchOnClick">{{ mdiMagnify }}</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="auto" v-if="create_route">
        <v-btn small block color="accent" @click="addOnClick">
          <v-icon>{{ plusIcon }}</v-icon>
          {{ $t('table.add_entity', { entity: $t(type) }) }}
        </v-btn>
      </v-col>
    </v-row>
    <!-- 
      The datatable 
      Mobile version of datatable is disabled because the custom header is not translated
      Issue: https://github.com/vuetifyjs/vuetify/issues/10818
    -->
    <v-data-table
      class="pl-0"
      :server-items-length="getTotalAmount"
      :mobile-breakpoint="0"
      :options.sync="options"
      :headers="getHeaders"
      :items="getItems"
      :loading="loading"
      :sort-by="sort_by"
      :sort-desc="sort_desc"
      @click:row="item_on_click"
      :loading-text="$t('table.loading')"
      :no-data-text="$t('table.no_data')"
      :no-result-text="$t('table.no_result')"
      :footer-props="{
        'items-per-page-text': $t('table.items_per_page'),
        'items-per-page-options': [10, 25, 50, 100],
      }"
    >
      <!-- Translate all headers-->
      <template v-for="hdr in getHeaders" v-slot:[`header.${hdr.value}`]="{ header }">
        {{ $t(header.text) }}
      </template>
      <template v-slot:[`item.image`]="{ item }">
        <v-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text my-2">
          <v-img :src="item['avatar']"></v-img>
        </v-avatar>
      </template>

      <template v-slot:[`item.actions`]="{ item }" v-if="hasActions">
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>{{ mdiDotsVertical }}</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item v-if="edit_route && show_edit(item)" @click="editOnClick(item)">
              <v-list-item-title>
                <v-icon size="20" class="me-2">
                  {{ mdiPencil }}
                </v-icon>
                <span>{{ $t('table.edit') }}</span>
              </v-list-item-title>
            </v-list-item>

            <v-list-item v-if="delete_endpoint && show_delete(item)" @click="openDeleteDialog(item)">
              <v-list-item-title>
                <v-icon size="20" class="me-2">
                  {{ mdiDelete }}
                </v-icon>
                <span>{{ $t('table.delete') }}</span>
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="show_extra_action(item) && $slots.extra_action" @click="extra_action_on_click(item)">
              <slot name="extra_action" />
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
      <template v-for="(slot, name) in $scopedSlots" v-slot:[name]="item">
        <slot :name="name" v-bind="item"></slot>
      </template>
    </v-data-table>

    <v-navigation-drawer
      v-model="filter_drawer"
      :stateless="saved_filters_dialog"
      width="400"
      temporary
      fixed
      :right="!$vuetify.rtl"
      class="app-customizer-drawer"
    >
      <div class="app-customizer-header customizer-section py-3">
        <h6 class="font-weight-semibold text-xl text--primary">{{ $t('table.filters') }}</h6>
        <span class="text--secondary">{{ $t('table.description') }}</span>
        <v-btn icon class="icon-customizer-close" @click="filter_drawer = false">
          <v-icon>
            {{ closeIcon }}
          </v-icon>
        </v-btn>
      </div>
      <v-divider></v-divider>
      <v-form @submit.prevent>
        <filters :header_items="getHeaders" />
        <div class="px-2 my-4">
          <v-btn @click="applyFilters" type="submit" block color="primary" class="mb-2">
            {{ $t('table.apply_filters') }}
          </v-btn>
          <v-btn @click="removeAllFilters" type="submit" block text class="mb-2">
            {{ $t('table.delete_all_filters') }}
          </v-btn>
          <v-btn @click="saved_filters_dialog = true" type="submit" block outlined color="primary">
            {{ $t('table.saved_filters') }}
          </v-btn>
        </div>
      </v-form>
    </v-navigation-drawer>

    <delete-dialog
      v-model="delete_item_dialog"
      :deleteOnClick="deleteOnClick"
      :type="$t(type)"
      :identifier_label="$t('label.' + user_friendly_identifier)"
      :identifier_value="
        selected_for_delete[user_friendly_identifier] ? selected_for_delete[user_friendly_identifier] : ''
      "
    />
    <saved-filters-dialog
      v-model="saved_filters_dialog"
      :entity="type"
      :header_items="JSON.parse(JSON.stringify(getHeaders))"
      :parrent_callback="applySavedFilter"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import Filters from '@/components/Datatable/Filters.vue'
import SavedFiltersDialog from '@/components/Datatable/SavedFiltersDialog.vue'
import xlsx from 'json-as-xlsx'
import {
  mdiClose,
  mdiPlus,
  mdiFilterOutline,
  mdiMagnify,
  mdiPencil,
  mdiDelete,
  mdiDotsVertical,
  mdiDownload,
  mdiCheckboxBlankCircle,
} from '@mdi/js'
import _ from 'lodash'

export default {
  name: 'Datatable',
  components: {
    DeleteDialog,
    Filters,
    SavedFiltersDialog,
  },
  props: {
    //Functie die afgaat wanneer op een item wordt geklikt
    item_on_click: {
      type: Function,
      required: false,
      default: () => {},
    },
    //De headers van dit datatable
    header_items: {
      type: Array,
      required: true,
    },
    //Het type data. Wordt gebruikt in het delete_dialog
    type: {
      type: String,
      required: true,
    },
    //Het endpoint waaruit de data wordt opgehaald
    get_endpoint: {
      type: String,
      required: true,
    },
    //Het endpoint waar een item kan worden verwijderd
    delete_endpoint: {
      type: String,
      required: false,
      default: null,
    },
    //Het endpoint waar een export kan worden gemaakt
    export_endpoint: {
      type: String,
      required: false,
      default: null,
    },
    //De route waar een item kan worden gewijzigd
    edit_route: {
      type: String,
      required: false,
      default: null,
    },
    //De route waar een item kan worden aangemaakt
    create_route: {
      type: String,
      required: false,
      default: null,
    },
    //Alternatieve functie voor het clicken om de add-item knop
    create_action: {
      type: Function,
      required: false,
      default: null,
    },
    //Wanneer moet de item-edit-knop zichtbaar zijn
    show_edit: {
      type: Function,
      required: false,
      default: () => {
        return true
      },
    },
    //Wanneer moet de item-delete-knop zichtbaar zijn
    show_delete: {
      type: Function,
      required: false,
      default: () => {
        return true
      },
    },
    //Toon de extra actie ja of nee
    show_extra_action: {
      type: Function,
      required: false,
      default: () => {
        return true
      },
    },
    //Onclick voor de extra actie
    extra_action_on_click: {
      type: Function,
      required: false,
      default: () => {},
    },
    //Gebruikt door het delete dialog om het to_be_delete_item te identificeren voor de gebruiker
    user_friendly_identifier: {
      type: String,
      required: false,
      default: 'id',
    },
    //Hoe wordt het geselecteerde item geidentificeerd door de api en vue-router
    api_identifier: {
      type: String,
      required: false,
      default: 'id',
    },
    sort_by: {
      type: Array,
      required: false,
      default: () => [],
    },
    sort_desc: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  created() {
    //Add a extra header to all items perform action on it.
    //For example edition or deleting the item
    this.header_items.push({
      text: 'table.actions',
      value: 'actions',
      width: '50',
      align: 'end',
      sortable: false,
      prevent_export: true,
    })

    // this.$set(this.header_items[0], 'search_value', [])
    //Add default search values and a search_operator
    this.header_items.forEach(function (header) {
      if (header.search_type) {
        if (!header.search_value) {
          Vue.set(header, 'search_value', { 0: '', 1: '' })
        }
        if (!header.search_operator) {
          if (header.search_type === 'text') {
            Vue.set(header, 'search_operator', 'like')
          } else if (header.search_type === 'enum') {
            Vue.set(header, 'search_operator', 'in')
          } else {
            Vue.set(header, 'search_operator', 'eq')
          }
        }
      }
    })
  },
  mounted() {
    //get data according to given get_endpoint
    this.getData()
  },
  data() {
    return {
      filter_drawer: false,
      itemToDelete: {},
      items: [],
      options: {},
      loading: true,
      total_amount: 0,
      typing_timeout: null,
      active_filter_count: 0,
      selected_for_delete: {},
      delete_item_dialog: false,

      saved_filters_dialog: false,

      simple_search_model: '',

      closeIcon: mdiClose,
      plusIcon: mdiPlus,
      mdiFilterOutline,
      mdiMagnify,
      mdiPencil,
      mdiDelete,
      mdiDotsVertical,
      mdiDownload,
      mdiCheckboxBlankCircle,
    }
  },

  computed: {
    getItems() {
      return this.items
    },
    hasActions() {
      return this.edit_route || this.delete_endpoint
    },
    getTotalAmount() {
      return this.total_amount
    },
    getHeaders() {
      return this.header_items.filter((header, index) => {
        if (this.header_items.length - 1 == index && !this.hasActions) {
          return false
        } else {
          return true
        }
      })
    },
    datatableOptions() {
      return {
        ...this.options,
      }
    },
  },

  methods: {
    addOnClick() {
      if (this.create_action) {
        this.create_action()
      } else {
        this.$router.push({
          name: this.create_route,
        })
      }
    },
    simpleSearchOnClick() {
      this.loading = true
      //reset normal filters
      this.active_filter_count = 0
      this.header_items.forEach(header => {
        if (header.search_type) {
          header.search_value[0] = ''
          header.search_value[1] = ''
        }
      })

      this.$http
        .get(this.get_endpoint, {
          params: { simple_search_by_keyname: this.simple_search_model },
        })
        .then(response => {
          // JSON stringify and parse so the data wont get deep cloned
          // Update: i dont think this is nescesary
          this.items = this.formatData(JSON.parse(JSON.stringify(response.data.data)))
          this.total_amount = response.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    editOnClick(item) {
      if (this.$options.propsData.hasOwnProperty('item_on_click')) {
        this.$options.propsData['item_on_click'](item)
      } else {
        let params = {}
        params[this.api_identifier] = item[this.api_identifier]
        this.$router.push({
          name: this.edit_route,
          params: params,
        })
      }
    },
    openDeleteDialog(item) {
      this.selected_for_delete = item
      this.delete_item_dialog = true
    },
    deleteOnClick() {
      this.$http
        .delete(this.delete_endpoint + '/' + this.selected_for_delete[this.api_identifier])
        .then(() => {
          this.getData()
        })
        .finally(() => {
          this.selected_for_delete = {}
          this.delete_item_dialog = false
        })
    },
    /**
     * Method to get the data from the api
     * @returns {Promise<void>}
     */
    getData() {
      this.loading = true
      this.$http
        .get(this.get_endpoint, {
          params: this.requestParams(),
        })
        .then(response => {
          // JSON stringify and parse so the data wont get deep cloned
          // Update: i dont think this is nescesary
          this.items = this.formatData(JSON.parse(JSON.stringify(response.data.data)))
          this.total_amount = response.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    requestParams() {
      var datatableOptions = this.datatableOptions
      var query = {}

      //required params
      query['page'] = datatableOptions.page
      query['page_size'] = datatableOptions.itemsPerPage

      //add the keys to the params
      if (datatableOptions.sortBy[0]) {
        query['sort'] = datatableOptions.sortBy[0]
        if (datatableOptions.sortDesc[0]) {
          query['direction'] = 'desc'
        } else {
          query['direction'] = 'asc'
        }
      }

      //reset active filter count
      this.active_filter_count = 0

      this.header_items.forEach(header => {
        //Make sure this field is searchable
        if (header.search_type) {
          if (header.search_value[0]) {
            //range needs multiple values so execute a different piece of code
            if (header.search_operator === 'range') {
              if (header.search_value[1]) {
                query['filter-' + header.value + '-' + header.search_operator + '-' + header.search_type] =
                  header.search_value[0]
                query['filter-' + header.value + '-' + header.search_operator + '-' + header.search_type + '-2'] =
                  header.search_value[1]
                this.active_filter_count = this.active_filter_count + 1
              }
            } else {
              query['filter-' + header.value + '-' + header.search_operator + '-' + header.search_type] =
                header.search_value[0]
              this.active_filter_count = this.active_filter_count + 1
            }
          }
        }
      })
      return query
    },
    /**
     * Method to format the data by the given data_format in the header
     * For example currency should be presented with a $ symbol
     */
    formatData(items) {
      //TODO check whats the backup value is for
      this.header_items.forEach(header => {
        let data_format = header.data_format
        if (data_format) {
          items.forEach(item => {
            _.set(item, header.value, Vue.filter(data_format)(_.get(item, header.value)))
          })
        }
      })
      return items
    },
    applyFilters() {
      this.simple_search_model = ''
      this.filter_drawer = false
      this.options.page = 1
      this.getData()
    },
    removeAllFilters() {
      this.header_items.forEach(header => {
        if (header.search_type) {
          header.search_value[0] = ''
          header.search_value[1] = ''
        }
      })
      this.filter_drawer = false
      this.active_filter_count = 0
      this.getData()
    },
    applySavedFilter(saved_filter) {
      let filters = JSON.parse(saved_filter.value)
      this.saved_filter_name = saved_filter.name
      this.header_items.forEach(header => {
        filters.forEach(filter => {
          if (header.value == filter.identifier) {
            header.search_value[0] = filter.value[0]
            header.search_value[1] = filter.value[1]
            header.search_operator = filter.operator
          }
        })
      })
      this.filter_drawer = false
      this.active_filter_count = 0
      this.getData()
    },
    exportData() {
      this.loading = true
      //include filters but set the pagesize to the max
      let params = this.requestParams()
      params.page_size = this.total_amount
      this.$http
        .get(this.get_endpoint, {
          params: params,
        })
        .then(response => {
          let export_items = this.formatData(JSON.parse(JSON.stringify(response.data.data)))
          let columns = []
          this.header_items.forEach(header => {
            if (header.prevent_export !== true) {
              columns.push({
                label: this.$t(header.text),
                value: header.value,
              })
            }
          })
          let data = [
            {
              sheet: this.$t(this.type),
              columns: columns,
              content: export_items,
            },
          ]

          let settings = {
            fileName: this.$t(this.type) + ' - ' + new Date().toLocaleDateString(), // Name of the resulting spreadsheet
            extraLength: 3, // A bigger number means that columns will be wider
            writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
          }

          xlsx(data, settings)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  watch: {
    // Watch for a change in the options of the datatable.
    // Examples are the change the page or ordening
    options: {
      handler(newVal, oldVal) {
        if (oldVal.itemsPerPage !== undefined && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.getData()
        }
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
//Styling from AppCustomizer.vue
.app-customizer-header {
  position: relative;
  .icon-customizer-close {
    position: absolute;
    @include ltr() {
      right: 20px;
    }
    @include rtl() {
      left: 20px;
    }
    top: 50%;
    transform: translateY(-50%);
  }
}
.v-data-table tr {
  cursor: pointer;
}

.customizer-section {
  padding: 24px;
}

//Stying to remove border-radius and boxshadow
.v-expansion-panels {
  border-radius: 0px !important;
}
.v-expansion-panel::before {
  box-shadow: none !important;
}
.v-application.theme--light .v-expansion-panel.v-expansion-panel--active {
  box-shadow: none !important;
}

.v-data-table thead tr th {
  align-items: center;
}
</style>

