import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[(_vm.method === 'yubikey')?_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-5"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"mb-6",attrs:{"max-width":"150","contain":"","src":_vm.app_logo}})],1)],1):_c('div',{staticClass:"my-8"},[(!_vm.qr_string)?_c('div',{staticStyle:{"height":"280px"}}):_c('qrcode-vue',{staticClass:"text-center",attrs:{"value":_vm.qr_string,"size":"280","level":"M"}})],1),_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.$t('page.twofactor.activate_twofactor'))+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.method === 'google-authenticator' ? _vm.$t('page.twofactor.activate_authenticator_description') : _vm.$t('page.twofactor.activate_yubikey_description'))+" ")]),(_vm.method == 'google-authenticator')?[_c(VRow,{staticClass:"my-1"},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://www.microsoft.com/en-us/security/mobile-authenticator-app","target":"_blank"}},'a',attrs,false),on),[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[_c(VImg,{attrs:{"src":require("@/assets/images/authenticators/microsoft.png")}})],1)],1)]}}],null,false,1671637967)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.profile.more_info', { item: 'Microsoft Authenticator' })))])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://googleauthenticator.net/","target":"_blank"}},'a',attrs,false),on),[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[_c(VImg,{attrs:{"src":require("@/assets/images/authenticators/google.png")}})],1)],1)]}}],null,false,2346683103)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.profile.more_info', { item: 'Google Authenticator' })))])])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VTooltip,{attrs:{"top":"","nudge-top":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({attrs:{"href":"https://authy.com/download/","target":"_blank"}},'a',attrs,false),on),[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"50px","color":"primary"}},[_c(VImg,{attrs:{"src":require("@/assets/images/authenticators/authy.png")}})],1)],1)]}}],null,false,2705734511)},[_c('span',[_vm._v(_vm._s(_vm.$t('page.profile.more_info', { item: 'Authy' })))])])],1)],1)]:_vm._e()],2),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VTextField,{staticClass:"mb-4",attrs:{"disabled":_vm.loading,"label":_vm.$t('label.code'),"error-messages":_vm.$t(_vm.code.error, { field: _vm.$t('label.code') }),"outlined":"","autofocus":"","hide-details":"auto","type":_vm.method === 'yubikey' ? 'password' : ''},model:{value:(_vm.code.model),callback:function ($$v) {_vm.$set(_vm.code, "model", $$v)},expression:"code.model"}}),_c(VTextField,{staticClass:"mb-6",attrs:{"disabled":_vm.loading,"label":_vm.$t('label.secondary_email'),"error-messages":_vm.$t(_vm.secondary_email.error, { field: _vm.$t('label.secondary_email') }),"placeholder":_vm.blurred_second_email,"persistent-placeholder":"","outlined":"","hide-details":"auto"},model:{value:(_vm.secondary_email.model),callback:function ($$v) {_vm.$set(_vm.secondary_email, "model", $$v)},expression:"secondary_email.model"}},[_c('template',{slot:"append"},[_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VIcon,_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiHelp))])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('page.twofactor.secondary_email_description')))])])],1)],2),_c(VBtn,{attrs:{"type":"submit","color":"primary","disabled":_vm.loading,"loading":_vm.loading,"block":""},on:{"click":_vm.activateTwofactor}},[_vm._v(" "+_vm._s(_vm.$t('page.twofactor.activate_twofactor'))+" ")]),_c(VBtn,{staticClass:"mt-4",attrs:{"color":"secondary","outlined":"","disabled":_vm.loading,"block":""},on:{"click":_vm.swapMethod}},[_vm._v(" "+_vm._s(_vm.method === 'google-authenticator' ? _vm.$t('page.twofactor.use_yubikey') : _vm.$t('page.twofactor.use_authenticator'))+" ")])],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center align-center"},[_c(VBtn,{attrs:{"color":"secondary","text":"","disabled":_vm.loading,"block":""},on:{"click":_vm.cancel}},[_c(VIcon,{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.arrow_left_icon)+" ")]),_vm._v(" "+_vm._s(_vm.$t('common.cancel'))+" ")],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}})])}
var staticRenderFns = []

export { render, staticRenderFns }