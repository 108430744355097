<template>
  <v-form @submit.prevent>
    <div class="pa-5">
      <v-row>
        <v-col v-for="(lang, i) in $store.getters['i18n/langs']" :key="i" cols="12">
          <v-text-field
            v-model="contenttype.translations[lang.key].name"
            :error-messages="$t(errors.translations[lang.key].name, { field: $t('label.name') })"
            :label="$t('label.name')"
            outlined
            hide-details="auto"
          >
            <template v-slot:append>
              <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
              <div class="mt-1">{{ lang.native }}</div>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-model="contenttype.api_identifier"
            disabled
            :label="$t('page.contenttype.api_identifier')"
            hide-details="auto"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-switch
            v-model="contenttype.is_sortable"
            :label="$t('page.contenttype.is_sortable')"
            hide-details="auto"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-switch
            v-model="contenttype.entries_public_get"
            :label="$t('page.contenttype.entries_public_get')"
            hide-details="auto"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-switch
            v-model="contenttype.entries_public_post"
            :label="$t('page.contenttype.entries_public_post')"
            hide-details="auto"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-switch
            v-model="contenttype.entries_public_update"
            :label="$t('page.contenttype.entries_public_update')"
            hide-details="auto"
            outlined
          />
        </v-col>
        <v-col cols="12">
          <v-switch
            v-model="contenttype.entries_public_delete"
            :label="$t('page.contenttype.entries_public_delete')"
            hide-details="auto"
            outlined
          ></v-switch>
        </v-col>mail_at_add
        
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="contenttype.mail_at_add"
            :label="$t('page.contenttype.mail_at_add')"
            hide-details="auto"
            outlined
            placeholder="mail@example.com"
          ></v-text-field>
        </v-col>
        
        <v-col cols="12">
          <v-btn
            :disabled="loading"
            :loading="loading"
            color="accent"
            type="submit"
            @click="editContenttype"
            block
            small
            class="mt-6"
          >
            <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
            {{ $t('common.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import { mdiContentSaveOutline } from '@mdi/js'

export default {
  components: {},
  props: {
    callback: {
      type: Function,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  // voordat het scherm zichtbaar wordt
  beforeMount() {
    // beschikbare talen ophalen
    this.langs = this.$store.getters['i18n/langs']
    this.langs.forEach(lang => {
      if (!this.contenttype.translations.hasOwnProperty(lang.key)) {
        this.contenttype.translations[lang.key] = {
          name: '',
        }
      }
      if (!this.errors.translations.hasOwnProperty(lang.key)) {
        this.errors.translations[lang.key] = {
          name: '',
        }
      }
    })
  },
  // beschikbare data voor in template definieren
  data() {
    return {
      loading: false, // deze is tijdens het laden false, hiermee worden de loader indicator, of de stijl van andere visuele componenten veranderd (disabled velden)
      contenttype: {
        translations: {},
        api_identifier: '',
        entries_public_get: false,
        entries_public_post: false,
        entries_public_update: false,
        entries_public_delete: false,
      },
      errors: {
        translations: {},
        api_identifier: '',
      },
      langs: [],
      //icons
      mdiContentSaveOutline,
    }
  },
  methods: {
    editContenttype() {
      this.loading = true

      this.$http
        .post('contenttypes/' + this.contenttype.api_identifier, this.contenttype)
        // then = een positief (2xx) response
        .then(res => {
          // bijgewerkte component naar parent sturen (de contenttypeFields.vue)
          this.$emit('input', this.contenttype)
          this.callback()
        })
        // 4xx 5xx responses, laravel returned per veld de error, dit gaan we matchen en plaatsen
        .catch(error => {
          if (typeof error == 'object' && typeof error.response.data.error == 'object') {
            this.errors = error.response.data.error
            if (!this.errors.hasOwnProperty('translations')) {
              this.errors.translations = {}
            }
            this.langs.forEach(lang => {
              if (!this.errors.translations.hasOwnProperty(lang.key)) {
                this.errors.translations[lang.key] = {
                  name: '',
                }
              }
            })
          }
        })
        // finally wordt altidj aangeroepen, componenten weer beschikbaar maken (loading false, blur eraf)
        .finally(() => {
          this.loading = false
          document.activeElement.blur()
        })
    },
  },
  watch: {
    value: function (value) {
      this.contenttype = value

      this.langs.forEach(lang => {
        if (this.contenttype.translations.length == 0) {
          this.contenttype.translations = {}
        }
        if (!this.contenttype.translations.hasOwnProperty(lang.key)) {
          this.contenttype.translations[lang.key] = {
            name: '',
          }
        }
        if (!this.errors.translations.hasOwnProperty(lang.key)) {
          this.errors.translations[lang.key] = {
            name: '',
          }
        }
      })
    },
  },
}
</script>
