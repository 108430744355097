// axios
import axios from 'axios'
import Vue from 'vue'
import store from '@/store'
import router from '@/router'

const axiosIns = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true
})

axiosIns.interceptors.request.use(
  config => {
    //Create config.params if not exist
    config.params = config.params || {};

    //Add lang to request
    config.headers['Accept-Language'] = store.getters['i18n/lang']

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(function (response) {
  //Any request with statuscode 2xx can be handeled here
  if (response.data.message) {
    store.commit('notification/notify', {
      text: response.data.message,
    })
  }
  return response;
}, function (error) {
  //Check if token is expired a route the user to login screen
  if (error.response.status === 401) {
    store.commit("user/logout")
    router.push('/login')
  }

  if (error.response.data.message) {
    store.commit('notification/notify', {
      text: error.response.data.message,
    })
  }

  return Promise.reject(error);
});

axiosIns.interceptors.request.use(function (config) {
  const jwt_token = store.state.user.jwt_token;
  if(jwt_token) {
    config.headers.Authorization = 'Bearer ' + jwt_token;
  }
  return config;
});

Vue.prototype.$http = axiosIns


export default axiosIns