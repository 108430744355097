<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img class="mb-6" max-width="150" contain :src="app_logo"></v-img>
          </router-link>
        </v-card-title>
        <v-card-text>
          <p class="text-2xl font-weight-semibold text--primary mb-2">
            {{ $t('page.twofactor.login_with_twofactor') }}
          </p>
          <p class="mb-2">
            {{
              method === 'google-authenticator'
                ? $t('page.twofactor.authenticator_description')
                : $t('page.twofactor.yubikey_description')
            }}
          </p>
        </v-card-text>

        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field
              v-model="code.model"
              :disabled="loading"
              :label="$t('label.code')"
              :error-messages="$t(code.error, { field: $t('label.code') })"
              outlined
              hide-details="auto"
              autofocus
              inputmode="numeric"
              class="mb-6"
              :type="method === 'yubikey' ? 'password' : ''"
            />

            <v-btn
              @click="loginWithAuthenticator"
              type="submit"
              color="accent"
              :disabled="loading"
              :loading="loading"
              block
            >
              {{ $t('page.twofactor.login_with_twofactor') }}
            </v-btn>

            <v-btn
              v-if="both_methods_activated"
              @click="swapMethod"
              color="secondary"
              outlined
              :disabled="loading"
              block
              class="mt-4"
            >
              {{
                method === 'google-authenticator'
                  ? $t('page.twofactor.use_yubikey')
                  : $t('page.twofactor.use_authenticator')
              }}
            </v-btn>

            <v-btn @click="cancel" color="secondary" text :disabled="loading" block class="mt-4">
              {{ $t('common.cancel') }}
            </v-btn>
            <v-btn small @click="lost_2fa_dialog = true" text :disabled="loading" block class="mt-2">
              {{ $t('page.twofactor.i_lost_my_2fa') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </div>

    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
    <v-dialog v-model="lost_2fa_dialog" max-width="550">
      <v-card class="py-8">
        <v-row no-gutters justify="center">
          <v-col cols="auto">
            <v-icon size="200">{{ mdiAlertCircleOutline }}</v-icon>
          </v-col>
        </v-row>
        <v-card-subtitle style="word-break: break-word" class="text-center justify-center text-h5 px-5">
          {{ $t('page.twofactor.lost_2fa_description') }}
        </v-card-subtitle>
        <v-card-text class="text-center mt-n2 px-5">
          {{ $t('page.twofactor.send_reset_email', { email: blurred_second_email }) }}
        </v-card-text>
        <v-row no-gutters justify="center">
          <v-col cols="auto">
            <v-btn @click="requestReset" class="accent">{{ $t('common.send_email') }}</v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'
import AlertDialog from '@/components/AlertDialog.vue'
import { mdiAlertCircleOutline } from '@mdi/js'

export default {
  name: 'TwofactorLogin',
  components: {
    AlertDialog,
  },
  beforeMount() {
    this.both_methods_activated = this.$store.state.user.activated_two_factor_methods.length === 2
    this.method = this.$store.state.user.preferred_two_factor_authentication_method
  },
  data: () => ({
    code: {
      model: '',
      error: '',
    },

    lost_2fa_dialog: false,
    blurred_second_email: '',

    loading: false,
    method: 'yubikey',
    both_methods_activated: false,

    app_logo: themeConfig.app.logo,
    mdiAlertCircleOutline,
  }),
  methods: {
    loginWithAuthenticator: function () {
      if (!this.code.model) {
        this.code.error = 'validation.required'
        return
      }

      this.code.error = ''
      this.loading = true

      this.$http
        .post('2fa/login', { 
          code: this.code.model, 
          method: this.method 
        })
        .then(res => {
          this.$store.commit('user/jwt_token', res.data.token)
          this.$store.commit('user/user', res.data.user)
          this.$router.push('/profile')
        })
        .catch(() => {
          this.code.error = 'validation.invalid_input'
        })
        .finally(() => {
          this.loading = false
        })
    },
    cancel: function () {
      this.$store.commit('user/logout')
      this.$router.push('/login')
    },
    swapMethod: function () {
      this.method === 'google-authenticator' ? (this.method = 'yubikey') : (this.method = 'google-authenticator')
    },
    requestReset() {
      this.$http.post('2fa/reset_request').then(() => {
        this.cancel()
      })
    },
  },
  watch: {
    lost_2fa_dialog: function (new_val) {
      if (new_val && this.blurred_second_email == '') {
        this.$http('2fa/hint_email').then(res => {
          this.blurred_second_email = res.data.blurred_second_email
        })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

