import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" "+_vm._s(_vm.$t('routes.rights'))+" ")]),_c(VDivider),_c('div',{staticClass:"px-5 pb-5",staticStyle:{"overflow":"auto"}},[_c('table',{staticClass:"permissions-datatable"},[_c('thead',[_c('tr',{staticStyle:{"margin-bottom":"2px"}},[_c('th'),_vm._l((Object.entries(_vm.roles)),function(ref,i){
var key = ref[0];
var role = ref[1];
return _c('th',{key:i},[_c('div',{staticClass:"mt-8 ml-2",staticStyle:{"height":"65px","width":"40px"}},[_c(VTooltip,{attrs:{"bottom":"","nudge-left":"15"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"text-start",staticStyle:{"transform":"rotate(-45deg)","white-space":"nowrap","width":"120px","text-overflow":"ellipsis","overflow":"hidden"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(role['translations'][_vm.$i18n.locale])+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(role['translations'][_vm.$i18n.locale])+" ")])])],1)])})],2)]),_c('permission-tree',{attrs:{"route_dependencies":_vm.route_dependencies,"role_models":_vm.model.roles,"loading":_vm.loading,"type":"vue","vueToggleOnClick":_vm.vueToggleOnClick,"laravelToggleOnClick":_vm.laravelToggleOnClick},on:{"update:loading":function($event){_vm.loading=$event}}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }