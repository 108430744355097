<template>
  <v-container fill-height>
    <v-row justify="center">
      <v-col sm="6" lg="4" xl="3" class="text-center">
        <v-progress-circular size="130" indeterminate color="primary"></v-progress-circular>
        <div class="text-h6 mt-4 font-weight-light">{{ $t('page.identify_callback.logging_you_in') }}</div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { PASSWORD } from '@/router'
import store from '@/store'

export default {
  beforeMount() {
    let code = new URLSearchParams(window.location.search).get('code')
    if (code) {
      this.$http
        .post('login_identify?code=' + code + '&redirect=' + window.location.pathname)
        .then(res => {
          store.commit('user/jwt_token', res.data.token)
          store.commit('user/user', res.data.user)
          this.$router.push('/profile')
        })
        .catch(err => {
          console.log(err)
          this.$router.push('/login')
        })
    } else {
      this.$router.push('/login')
    }
  },
}
</script>