import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"550"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{staticClass:"py-8"},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"size":"200"}},[_vm._v(_vm._s(_vm.mdiAlertCircleOutline))])],1)],1),_c(VCardTitle,{staticClass:"text-center justify-center text-h5 px-5"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),_c(VCardText,{staticClass:"text-center mt-n2 px-5"},[_vm._v(" "+_vm._s(_vm.$t(_vm.description))+" ")]),_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"error",on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(_vm._s(_vm.$t('common.ok')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }