<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link to="/" class="brand-logo d-flex align-center">
        <v-img :src="appLogo" max-height="35px" max-width="100px" alt="logo" contain class="me-3"></v-img>
      </router-link>
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />

            <!-- Logo -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              
            </div>
          </div>
        </v-col>

        <v-col lg="4" class="d-flex flex-column auth-bg">
          <v-row class="shrink mt-1 mr-1" align="center">
            <v-spacer></v-spacer>
            <v-col class="shrink text-center mr-n2">
              <locale-changer></locale-changer>
            </v-col>
            <v-col class="shrink">
              <app-bar-theme-switcher></app-bar-theme-switcher>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="mb-8">
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ $t('page.login.welcome_to') + ' ' + appName + '!' }}
                  </p>
                  <p class="mb-2">{{ $t('page.login.please_sign_in') }}</p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form @submit.prevent>
                    <v-text-field
                      v-model="email.model"
                      outlined
                      :disabled="loading"
                      :label="$t('label.email')"
                      :error="email.error"
                      :error-messages="$t(email.message, { field: $t('label.email') })"
                      hide-details="auto"
                      autofocus
                      class="mb-6"
                    ></v-text-field>

                    <div v-if="!useIdentify">
                      <v-text-field
                        v-model="password.model"
                        outlined
                        :disabled="loading"
                        :type="isPasswordVisible ? 'text' : 'password'"
                        :label="$t('label.password')"
                        :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                        :error="password.error"
                        :error-messages="$t(password.message, { field: $t('label.password') })"
                        hide-details="auto"
                        class="mb-2"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>

                      <div class="d-flex align-center justify-space-between flex-wrap">
                        <v-checkbox hide-details :label="$t('page.login.remember_me')" class="mt-0"> </v-checkbox>
                        <router-link to="/forgot-password" class="d-flex align-center text-sm">
                          <span>{{ $t('page.login.forgot_password') }}</span>
                        </router-link>
                      </div>
                    </div>

                    <v-btn
                      block
                      :disabled="loading"
                      :loading="loading"
                      :color="useIdentify ? 'secondary' : 'accent'"
                      type="submit"
                      @click="loginOnClick"
                      class="mt-6"
                    >
                      {{ $t('page.login.login') }}
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref, reactive, watch } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import LocaleChanger from '@/components/LocaleChanger.vue'
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue'

import axios from '@/plugins/axios'
import router from '@/router'
import store from '@/store'

export default {
  components: {
    LocaleChanger,
    AppBarThemeSwitcher,
  },
  setup() {
    const isPasswordVisible = ref(false)
    const useIdentify = ref(false)

    const loading = ref(false)

    const email = reactive({
      model: '',
      error: false,
      message: '',
    })
    const password = reactive({
      model: '',
      error: false,
      message: '',
    })

    const loginOnClick = () => {
      loading.value = true
      if (!useIdentify.value) {
        axios
          .post('/login', {
            email: email.model,
            password: password.model,
          })
          .then(res => {
            store.commit('user/jwt_token', res.data.token)
            store.commit('user/user', res.data.user)
            router.push('/profile')
          })
          .catch(err => {
            if (err.response.status === 400) {
              email.error = true
              password.error = true
              password.message = 'validation.incorrect_credentials'
              return
            }

            let errors = err.response.data.error
            if (errors.email) {
              Object.assign(email, {
                error: true,
                message: errors.email[0],
              })
            } else {
              Object.assign(email, {
                error: false,
                message: '',
              })
            }
            if (errors.password) {
              Object.assign(password, {
                error: true,
                message: errors.password[0],
              })
            } else {
              Object.assign(password, {
                error: false,
                message: '',
              })
            }
          })
          .finally(() => {
            loading.value = false
          })
      } else {
        loading.value = false
        const location = window.location
        window.location.href =
          'https://identify2.i-design.nu/oauth/authorize?client_id=1&redirect_uri=' +
          location.protocol +
          '//' +
          location.hostname +
          '/login/identify/callback&response_type=code'
      }
    }

    watch(
      () => email.model,
      newValue => {
        if (newValue.includes('@i-design.nu')) {
          useIdentify.value = true
        } else {
          useIdentify.value = false
        }
      },
    )

    return {
      //onClickListeners
      loginOnClick,

      //UI
      isPasswordVisible,
      useIdentify,
      loading,

      //models
      email,
      password,

      // Icons
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
