<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title>{{
            $t('page.contenttype_entries.add_entry_title', {
              type: getContenttypeName,
            })
          }}</v-card-title>
          <v-divider />
          <div class="pa-5" v-if="contenttype_fields == 0">{{ $t('page.contenttype_entries.no_fields') }}</div>
          <contententry-input
            v-for="(field, index) in contenttype_fields"
            v-model="contenttype_fields[index]"
            :key="index"
          />
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <v-btn
              @click="addContenttypeEntry"
              :disabled="loading"
              :loading="loading"
              color="accent"
              type="submit"
              block
              small
              class="mb-3"
            >
              <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
              {{ $t('common.save') }}
            </v-btn>
            <v-btn small block outlined :disabled="loading" @click="$router.push('/contenttype/entries')">
              <v-icon class="ml-r" small>{{ mdiArrowULeftTop }}</v-icon>
              {{ $t('common.back') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mdiArrowULeftTop, mdiContentSaveOutline } from '@mdi/js'
import ContententryInput from './ContententryInput.vue'
export default {
  components: {
    ContententryInput,
  },
  beforeMount() {
    this.langs = this.$store.getters['i18n/langs']

    //get contenttype, fields
    this.$http('contenttypes/' + this.$route.params.api_identifier).then(res => {
      this.contenttype = res.data
    })
    this.$http('contenttypes/' + this.$route.params.api_identifier + '/fields?sort=order_index&direction=asc').then(
      res => {
        //format fields
        res.data.data.forEach(field => {
          //adding model and error
          field.model = ''
          field.error = ''

          //create a different model for translatable fields
          if (field.is_translatable) {
            field.model = {}
            this.langs.forEach(lang => {
              field.model[lang.key] = {
                model: '',
                error: '',
              }
            })
          }

          if (field.type == 'boolean') {
            field.model = false
          }

          if (field.type == 'file') {
            field.model = []
          }
        })
        this.contenttype_fields = res.data.data
      },
    )
  },
  data() {
    return {
      title: '',
      contenttype: {},
      contenttype_fields: [],
      loading: false,
      //icons
      mdiArrowULeftTop,
      mdiContentSaveOutline,
    }
  },
  methods: {
    addContenttypeEntry() {
      var request_data = new FormData()
      this.contenttype_fields.forEach(field => {
        if (field.is_translatable) {
          for (var prop in field.model) {
            request_data.append(field.api_identifier + '[' + prop + ']', field.model[prop].model)
          }
        } else if (field.type == 'file') {
          for (let i = 0; i < document.getElementById(field.api_identifier).files.length; i++) {
            request_data.append(field.api_identifier + '-' + i, document.getElementById(field.api_identifier).files[i])
          }
        } else {
          if (field.model !== '') {
            request_data.append(field.api_identifier, field.model)
          }
        }
      })

      this.$http
        .post('contenttypes/' + this.$route.params.api_identifier + '/entries/create', request_data)
        .then(res => {
          this.$router.push('/contenttype/' + this.$route.params.api_identifier + '/entries/edit/' + res.data.id)
        })
        .catch(error => {
          if (!error.response) {
            this.$store.commit('notification/notify', {
              text: 'notification.cms_entries_payload_to_big',
            })
          } else if (typeof error == 'object' && typeof error.response.data.error == 'object') {
            this.errors = error.response.data.error

            this.contenttype_fields.forEach(field => {
              if (field.is_translatable) {
                this.langs.forEach(lang => {
                  if (this.errors[field.api_identifier] && this.errors[field.api_identifier][lang.key]) {
                    field.model[lang.key].error = this.errors[field.api_identifier][lang.key]
                  } else {
                    field.model[lang.key].error = ''
                  }
                })
              } else {
                if (this.errors[field.api_identifier]) {
                  field.error = this.errors[field.api_identifier]
                } else {
                  field.error = ''
                }
              }
            })

            if (!this.errors.hasOwnProperty('translations')) {
              this.errors.translations = {}
            }
            this.langs.forEach(lang => {
              if (!this.errors.translations.hasOwnProperty(lang.key)) {
                this.errors.translations[lang.key] = {
                  name: '',
                }
              }
            })
          }
        })
    },
  },
  computed: {
    getContenttypeName() {
      if (Object.keys(this.contenttype).length > 0) {
        return Vue.filter('translated')(this.contenttype.contenttype_translations)
      }
      return ''
    },
  },
  watch: {},
}
</script>