import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-5"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"mb-2",attrs:{"max-width":"150","contain":"","src":_vm.appLogo}})],1)],1),(_vm.loading)?_c(VRow,{staticClass:"mb-6",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VProgressCircular,{attrs:{"color":"primary","indeterminate":"","size":"150"}})],1)],1):[_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.$t('page.personify_accepted.title'))+" ")]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('page.personify_accepted.description')))])]),_c(VCardText,[(_vm.$store.state.user.id)?_c(VBtn,{staticClass:"primary",attrs:{"block":""},on:{"click":function($event){return _vm.$router.push('/profile')}}},[_vm._v(" "+_vm._s(_vm.$t('page.personify_accepted.to_profile'))+" ")]):_c(VBtn,{staticClass:"accent",attrs:{"block":""},on:{"click":function($event){return _vm.$router.push('login')}}},[_vm._v(" "+_vm._s(_vm.$t('page.personify_accepted.to_login'))+" ")])],1)]],2)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}})])}
var staticRenderFns = []

export { render, staticRenderFns }