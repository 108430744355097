import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1"},[_c('div',{staticClass:"auth-inner"},[_c(VCard,{staticClass:"auth-card"},[_c(VCardTitle,{staticClass:"d-flex align-center justify-center py-5"},[_c('router-link',{staticClass:"d-flex align-center",attrs:{"to":"/"}},[_c(VImg,{staticClass:"mb-6",attrs:{"max-width":"150","contain":"","src":_vm.appLogo}})],1)],1),_c(VCardText,[_c('p',{staticClass:"text-2xl font-weight-semibold text--primary mb-2"},[_vm._v(" "+_vm._s(_vm.$t('page.forgot_password.forgot_password'))+" 🔒 ")]),_c('p',{staticClass:"mb-2"},[_vm._v(_vm._s(_vm.$t('page.forgot_password.description')))])]),_c(VCardText,[_c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VTextField,{staticClass:"mb-4",attrs:{"outlined":"","label":_vm.$t('label.email'),"hide-details":"auto","error-messages":_vm.$t(_vm.email.error, { field: _vm.$t('label.email') }),"disabled":_vm.loading,"autofocus":""},model:{value:(_vm.email.model),callback:function ($$v) {_vm.$set(_vm.email, "model", $$v)},expression:"email.model"}}),_c(VBtn,{attrs:{"type":"submit","block":"","color":"accent","disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.sendMailOnClick}},[_vm._v(" "+_vm._s(_vm.$t('page.forgot_password.reset_button'))+" ")])],1)],1),_c(VCardActions,{staticClass:"d-flex justify-center align-center"},[_c('router-link',{staticClass:"d-flex align-center text-sm",attrs:{"to":"/"}},[_c(VIcon,{attrs:{"size":"24","color":"primary"}},[_vm._v(" "+_vm._s(_vm.arrowLeftIcon)+" ")]),_c('span',[_vm._v(_vm._s(_vm.$t('page.forgot_password.back_to_login')))])],1)],1)],1)],1),_c('img',{staticClass:"auth-mask-bg",attrs:{"height":"190","src":require(("@/assets/images/misc/mask-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light') + ".png"))}})])}
var staticRenderFns = []

export { render, staticRenderFns }