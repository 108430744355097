import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.user.personified)?_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({staticClass:"mx-6",attrs:{"color":"error","outlined":"","icon":_vm.$vuetify.breakpoint.mobile},on:{"click":_vm.stopPersonification}},'v-btn',attrs,false),on),[(_vm.$vuetify.breakpoint.mobile)?_c(VIcon,[_vm._v(_vm._s(_vm.mdiCardAccountDetailsOutline))]):[_vm._v(" "+_vm._s(_vm.$t('personify.personified_as', { name: _vm.$store.state.user.firstname }))+" ")]],2)]}}],null,false,222607315)},[_c('span',[_vm._v(_vm._s(_vm.$t('personify.stop_personification')))])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }