<template>
  <v-dialog v-model="open" persistent max-width="800">
    <v-card style="overflow: hidden">
      <v-card-title class="d-flex justify-space-between">
        <template>
          {{ $t('common.edit') }} {{ $t('page.contenttype.types.' + contenttype_field.type + '.name') }}
        </template>
        <v-btn icon @click="open = false">
          <v-icon>{{ mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider />
      <div class="pa-5">
        <v-row class="py-1">
          <v-col cols="12" md="6">
            <v-row>
              <v-col v-for="(lang, i) in langs" :key="i" cols="12">
                <v-text-field
                  v-model="contenttype_field.translations[lang.key].name"
                  :error-messages="
                    $t(errors.translations[lang.key].name, { field: $t('label.regular_expression_error') })
                  "
                  :label="$t('label.name')"
                  outlined
                  hide-details="auto"
                  @input="translationChanges++"
                >
                  <template v-slot:append>
                    <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
                    <div class="mt-1">{{ lang.native }}</div>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="contenttype_field.api_identifier"
              :label="$t('label.field_id')"
              outlined
              hide-details="auto"
              disabled
            />
            <div class="py-1">{{ $t('page.contenttype.field_id_description') }}</div>
          </v-col>

          <v-col cols="12" class="mt-n5">
            <v-select
              v-if="contenttype_field.type == 'option'"
              v-model="contenttype_field.options_from_contenttype_id"
              :items="[contenttype_field.options_from_contenttype]"
              :item-text="item => item.translations[$i18n.locale].name"
              item-value="id"
              :label="$t('entity.contenttype')"
              disabled
            />
            <v-checkbox
              v-if="show_is_required"
              v-model="contenttype_field.is_required"
              hide-details="auto"
              :label="$t('page.contenttype.required')"
            />
            <v-checkbox
              v-if="show_is_title"
              v-model="contenttype_field.is_content_title"
              hide-details="auto"
              :label="$t('page.contenttype.represents_entry_title')"
              class="mt-1"
            />
            <template v-if="show_is_translatable">
              <v-checkbox
                v-model="contenttype_field.is_translatable"
                hide-details="auto"
                :label="$t('page.contenttype.enable_localization')"
                class="mt-1"
                disabled
              />
              <div class="font-weight-light ml-8">{{ $t('page.contenttype.enable_localization_sub') }}</div>
              <div class="font-weight-light ml-8 mb-4">
                <div v-for="lang in $store.getters['i18n/langs']" :key="lang.key">- {{ lang.native }}</div>
              </div>
            </template>
            <template v-if="contenttype_field.type == 'datetime'">
              <v-checkbox
                v-model="contenttype_field.settings.include_time"
                hide-details="auto"
                :label="$t('page.contenttype.include_time')"
                class="mt-1"
                disabled
              />
            </template>
            <template v-if="contenttype_field.type == 'file'">
              <v-combobox
                v-model="contenttype_field.settings.file_extentions"
                :label="$t('page.contenttype.file_extentions')"
                :hint="$t('page.contenttype.file_extentions_hint')"
                multiple
                clearable
                chips
              ></v-combobox>
              <v-checkbox
                v-model="contenttype_field.settings.multiple_files"
                hide-details="auto"
                :label="$t('page.contenttype.multiple_files')"
                class="mt-1"
              />
            </template>

            <template v-if="contenttype_field.type == 'text' || contenttype_field.type == 'textarea'">
              <v-checkbox
                v-model="use_regex"
                hide-details="auto"
                :label="$t('page.contenttype.use_regex')"
                class="mt-1 mb-4"
              />
            </template>
            <template v-if="(contenttype_field.type == 'text' || contenttype_field.type == 'textarea') && use_regex">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="contenttype_field.validation_regular_expression"
                    :error-messages="
                      $t(errors.validation_regular_expression, { field: $t('label.validation_regular_expression') })
                    "
                    :label="$t('label.validation_regular_expression')"
                    outlined
                    hide-details="auto"
                  >
                    <template v-slot:append-outer>
                      <v-btn class="mt-n3" icon @click="regex_suggetion_dialog = true">
                        <v-icon>{{ mdiBookOpenPageVariant }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col v-for="(lang, i) in langs" :key="'regular_expression_error_' + i" cols="12">
                  <v-text-field
                    v-model="contenttype_field.translations[lang.key].regular_expression_error"
                    :error-messages="
                      $t(errors.translations[lang.key].regular_expression_error, {
                        field: $t('label.regular_expression_error'),
                      })
                    "
                    :label="$t('label.regular_expression_error')"
                    outlined
                    hide-details="auto"
                    @input="translationChanges++"
                  >
                    <template v-slot:append>
                      <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
                      <div class="mt-1">{{ lang.native }}</div>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-col>
        </v-row>
        <div class="d-flex justify-end mt-2" style="width: 100%">
          <v-btn :disabled="!translationsAreFilled" :loading="loading" color="accent" @click="editField()">
            <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
            {{ $t('common.save') }}
          </v-btn>
        </div>
      </div>
    </v-card>

    <regex-suggestion-dialog v-model="regex_suggetion_dialog" :selectPattern="selectRegexPattern" />
  </v-dialog>
</template>

<script>
import RegexSuggestionDialog from './RegexSuggestionDialog.vue'
import {
  mdiAlert,
  mdiFormatText,
  mdiTextBox,
  mdiNumeric,
  mdiClipboardTextClock,
  mdiCheckboxOutline,
  mdiCog,
  mdiCogs,
  mdiContentSaveOutline,
  mdiBookOpenPageVariant,
  mdiLock,
  mdiClose,
} from '@mdi/js'

export default {
  name: 'ContentfieldEditDialog',
  components: {
    RegexSuggestionDialog,
  },
  props: {
    contenttypeFieldData: {
      type: Object,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    refreshParrent: {
      type: Function,
      required: true,
    },
  },
  beforeMount() {
    this.langs = this.$store.getters['i18n/langs']
    this.refresh({
      type: '',
      translations: {},
      api_identifier: '',
      is_required: true,
      is_content_title: false,
      is_translatable: false,
      options_from_contenttype_id: undefined,
      validation_regular_expression: undefined,
    })
  },
  data: () => {
    return {
      open: false,
      loading: false,
      translationChanges: 0,
      use_regex: false,
      contenttype_field: '',

      regex_suggetion_dialog: false,
      errors: {
        translations: {},
      },
      langs: [],

      //vars used by the checkboxes to disable certain options
      show_is_required: true,
      show_is_translatable: true,
      show_is_title: true,

      //icons
      mdiFormatText,
      mdiTextBox,
      mdiNumeric,
      mdiClipboardTextClock,
      mdiCheckboxOutline,
      mdiCog,
      mdiCogs,
      mdiAlert,
      mdiLock,
      mdiClose,
      mdiBookOpenPageVariant,
      mdiContentSaveOutline,
    }
  },

  methods: {
    refresh(data) {
      this.errors = {
        translations: {},
      }
      this.contenttype_field = JSON.parse(JSON.stringify(data))
      if (Array.isArray(this.contenttype_field.settings)) {
        this.contenttype_field.settings = {}
      }

      this.langs.forEach(lang => {
        if (!this.contenttype_field.translations.hasOwnProperty(lang.key)) {
          this.contenttype_field.translations[lang.key] = {
            name: '',
            regular_expression_error: '',
          }
        }
        this.errors.translations[lang.key] = {
          name: '',
          regular_expression_error: '',
        }
      })

      this.show_is_translatable = false
      this.show_is_title = false
      this.show_is_required = true
      switch (this.contenttypeFieldData.type) {
        case 'text':
          this.show_is_translatable = true
          this.show_is_title = true
          if (this.contenttypeFieldData.validation_regular_expression) {
            this.use_regex = true
          }
          break
        case 'textarea':
          this.show_is_translatable = true
          if (this.contenttypeFieldData.validation_regular_expression) {
            this.use_regex = true
          }
          break
        case 'richtext':
          break
        case 'number':
          break
        case 'datetime':
          break
        case 'boolean':
          this.show_is_required = false
          break
        case 'options':
          break
        case 'option':
          break
        case 'file':
          break
      }

      this.translationChanges++
    },
    editField() {
      this.loading = true

      this.$http
        .post(
          '/contenttypes/' + this.$route.params.api_identifier + '/fields/' + this.contenttype_field.id,
          this.contenttype_field,
        )
        .then(res => {
          this.open = false
          this.refreshParrent()
        })
        .catch(error => {
          if (typeof error == 'object' && typeof error.response.data.error == 'object') {
            this.errors = error.response.data.error
            if (!this.errors.hasOwnProperty('translations')) {
              this.errors.translations = {}
            }
            this.langs.forEach(lang => {
              if (!this.errors.translations.hasOwnProperty(lang.key)) {
                this.errors.translations[lang.key] = {
                  name: '',
                  regular_expression_error: '',
                }
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    selectRegexPattern(pattern, translationKey) {
      this.contenttype_field.validation_regular_expression = pattern

      if (translationKey) {
        let translationKeys = translationKey.split('.')
        this.langs.forEach(lang => {
          let msg = this.$i18n.messages[lang.key]
          translationKeys.forEach(key => {
            if (msg instanceof Object && msg.hasOwnProperty(key)) {
              msg = msg[key]
            } else {
              msg = null
            }
          })

          this.contenttype_field.translations[lang.key].regular_expression_error = msg
        })
      }
    },
    getNestedContenttype() {
      this.$http('/contenttypes/' + this.contenttype_field.options_from_contenttype_id).then(res => {
        this.contenttypes = res.data.data
      })
    },
  },
  computed: {
    translationsAreFilled() {
      // dit loze veld is nodig hier zodat er wel wordt gekeken naar de wijzigingen van de translations, deze zijn er pas na de beforeMount dus anders doet deze niets
      this.translationChanges

      let result = true
      this.langs.forEach(lang => {
        if (
          !this.contenttype_field.translations.hasOwnProperty(lang.key) ||
          this.contenttype_field.translations[lang.key].name == ''
        ) {
          result = false
        }
      })
      return result
    },
  },
  watch: {
    contenttypeFieldData(newVal) {
      this.refresh(newVal)
    },
    value: {
      handler(newVal) {
        this.open = newVal
      },
    },
    open(newVal) {
      this.refresh(this.contenttypeFieldData)
      if (!newVal) {
        this.$emit('input', newVal)
      }
    },
    'contenttype_field.settings.file_extentions': function (newVal) {
      if (newVal)
        newVal.forEach(ext => {
          if (ext.match(/\.[0-9a-z]+$/i) == null) {
            this.contenttype_field.settings.file_extentions = this.contenttype_field.settings.file_extentions.filter(
              e => e !== ext,
            )
            console.log(this.contenttype_field)
            return
          }
        })
    },
  },
}
</script>