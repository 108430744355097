<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title>
            {{ $t('entity.contenttype') + ': ' }}
            {{ contenttype.translations[$i18n.locale].name }}
          </v-card-title>
          <v-divider />

          <div class="pa-5" v-if="contenttype_fields == 0">{{ $t('page.contenttype.no_fields') }}</div>
          <div v-else class="pa-5">
            <draggable element="div" v-model="contenttype_fields" v-bind="dragOptions" @end="updateOrder">
              <transition-group type="transition" tag="ul">
                <v-sheet
                  v-for="field in contenttype_fields"
                  @click="
                    selected_for_edit = field
                    edit_item_dialog = true
                  "
                  style="width: 100%"
                  rounded="xl"
                  :key="field.id"
                  outlined
                  class="pa-5 mb-2 list-group-item"
                >
                  <v-row>
                    <v-col class="d-flex align-center">
                      <v-icon class="mr-2 list-group-item-drag">{{ mdiDrag }}</v-icon>
                      <v-icon class="mr-2">{{ icons[field.type] }}</v-icon>
                      <div class="font-weight-light mr-1">
                        {{ $t('page.contenttype.types.' + field.type + '.name') }}:
                      </div>
                      <div class="font-weight-bold">
                        {{ field.translations[$i18n.locale].name }}
                      </div>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="d-flex justify-end">
                      <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>{{ mdiDotsVertical }}</v-icon>
                          </v-btn>
                        </template>

                        <v-list>
                          <v-list-item
                            :disabled="loading"
                            :loading="loading"
                            @click="
                              selected_for_edit = field
                              edit_item_dialog = true
                            "
                          >
                            <v-list-item-title>
                              <v-icon size="20" class="me-2">
                                {{ mdiPencil }}
                              </v-icon>
                              <span>{{ $t('table.edit') }}</span>
                            </v-list-item-title>
                          </v-list-item>

                          <v-list-item
                            @click="
                              delete_item_dialog = true
                              selected_for_delete = field
                            "
                          >
                            <v-list-item-title>
                              <v-icon size="20" class="me-2">
                                {{ mdiDelete }}
                              </v-icon>
                              <span>{{ $t('table.delete') }}</span>
                            </v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-sheet>
              </transition-group>
            </draggable>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="loading"
              :loading="loading"
              color="accent"
              type="submit"
              @click="create_item_dialog = true"
              block
              small
              class="mb-3"
              >{{ $t('page.contenttype.add_field') }}
            </v-btn>
            <v-btn small block outlined :disabled="loading" @click="$router.push('/contenttypes')">
              {{ $t('common.back') }}
              <v-icon class="ml-1" small>{{ mdiArrowULeftTop }}</v-icon>
            </v-btn>
          </v-card-text>
          <v-divider class="my-1"></v-divider>
          <v-card-text>
            <v-btn small block outlined :disabled="loading" @click="open_navigation_drawer = true" class="mb-3">
              {{ $t('common.edit') }}
            </v-btn>
            <deleteButton
              :deleteOnClick="deleteContenttype"
              :type="$t('entity.contenttype')"
              :identifier_value="contenttype.translations[$i18n.locale].name"
              :identifier_label="$t('label.name')"
            ></deleteButton>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-navigation-drawer
      v-model="open_navigation_drawer"
      width="500"
      temporary
      fixed
      :right="!$vuetify.rtl"
      class="app-customizer-drawer"
    >
      <v-row class="pa-5" justify="space-between">
        <v-col cols="auto">
          <h6 class="font-weight-semibold text-xl text--primary">
            {{ $t('table.edit_entity', { entity: $t('entity.contenttype') }) }}
          </h6>
        </v-col>
        <v-col cols="auto">
          <v-btn icon class="icon-customizer-close" @click="open_navigation_drawer = false">
            <v-icon>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <contenttype-edit
        :callback="
          () => {
            open_navigation_drawer = false
          }
        "
        v-model="contenttype"
      />
    </v-navigation-drawer>
    <contentfield-create-dialog v-model="create_item_dialog" :refreshParrent="getFields" />
    <contentfield-edit-dialog
      v-model="edit_item_dialog"
      :refreshParrent="getFields"
      :contenttypeFieldData="selected_for_edit"
    />

    <delete-dialog
      v-model="delete_item_dialog"
      :deleteOnClick="deleteContenttypeField"
      :type="$t('entity_with_article.contenttypeField')"
      :identifier_label="$t('label.name')"
      :identifier_value="
        selected_for_delete != null
          ? selected_for_delete.contenttype_field_translations[
              localeIndex(selected_for_delete.contenttype_field_translations, 'locale')
            ].name
          : ''
      "
    />
  </v-form>
</template>

<script>
import Vue from 'vue'
import ContentfieldCreateDialog from './CreateDialog.vue'
import ContentfieldEditDialog from './EditDialog.vue'
import ContenttypeEdit from '@/pages/contenttypes/ContenttypeEdit.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import DeleteButton from '@/components/DeleteButton.vue'
import draggable from 'vuedraggable'
import {
  mdiArrowULeftTop,
  mdiAlert,
  mdiFormatText,
  mdiTextBox,
  mdiNumeric,
  mdiClipboardTextClock,
  mdiCheckboxOutline,
  mdiCog,
  mdiCogs,
  mdiBookmarkBoxMultipleOutline,
  mdiDrag,
  mdiDotsVertical,
  mdiPencil,
  mdiDelete,
  mdiClose,
} from '@mdi/js'

export default {
  name: 'ContenttypeFields',
  components: {
    draggable,
    ContenttypeEdit,
    ContentfieldCreateDialog,
    ContentfieldEditDialog,
    DeleteDialog,
    DeleteButton,
  },

  // voordat het scherm zichtbaar wordt
  beforeMount() {
    // beschikbare talen ophalen
    this.langs = this.$store.getters['i18n/langs']
    this.langs.forEach(lang => {
      if (!this.contenttype.translations.hasOwnProperty(lang.key)) {
        this.contenttype.translations[lang.key] = {
          name: '',
        }
      }
    })

    // contenttype ophalen
    this.$http('contenttypes/' + this.$route.params.api_identifier)
      .then(res => {
        this.contenttype = res.data

        this.langs.forEach(lang => {
          if (!this.contenttype.translations.hasOwnProperty(lang.key)) {
            this.contenttype.translations[lang.key] = {
              name: '',
            }
          }
        })
      })
      .catch(err => {
        console.log(err)
        this.$router.push('/contenttypes')
      })

    this.getFields()
  },
  data() {
    return {
      loading: false,
      contenttype: {
        translations: {},
      },
      create_item_dialog: false,
      edit_item_dialog: false,
      selected_for_edit: {},
      delete_item_dialog: false,
      selected_for_delete: null,
      contenttype_fields: [],
      delayedDragging: false,

      open_navigation_drawer: false,

      mdiArrowULeftTop,
      mdiDrag,
      mdiDotsVertical,
      mdiPencil,
      mdiDelete,
      mdiClose,

      icons: {
        text: mdiFormatText,
        textarea: mdiTextBox,
        number: mdiNumeric,
        datetime: mdiClipboardTextClock,
        boolean: mdiCheckboxOutline,
        options: mdiCogs,
        option: mdiCog,
        nested_contenttype: mdiBookmarkBoxMultipleOutline,
      },
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
  },
  methods: {
    getFields() {
      this.$http('/contenttypes/' + this.$route.params.api_identifier + '/fields').then(res => {
        this.contenttype_fields = res.data.data.sort((a, b) =>
          a.order_index > b.order_index ? 1 : b.order_index > a.order_index ? -1 : 0,
        )
      })
    },

    updateContenttype() {
      this.loading = true

      // verzamelen request data
      let requestData = {}

      // Voor de meertaligheid moeten we dat even formatten van name[i].model en name[i].error naar requestData['name'][this.langs[i].key].
      requestData = {}

      this.$http
        .update('/contenttypes/' + this.$route.params.api_identifier + '/fields')

        // then = een positief (2xx) response
        .then(() => {})
        // 4xx 5xx responses, laravel returned per veld de error, dit gaan we matchen en plaatsen
        .catch(error => {})
        // finally wordt altidj aangeroepen, componenten weer beschikbaar maken (loading false, blur eraf)
        .finally(() => {
          this.loading = false
        })
    },
    editContenttypeField(field) {
      // this.$router.push({'path':'/contenttypes/' + this.$route.params.api_identifier + '/fields/' + field.id});
      this.editFieldDialogOpen = true
      // this.contenttypeField = field.id;
    },
    deleteContenttypeField(index) {
      this.loading = true
      this.$http
        // Delete calletje doen
        .delete('/contenttypes/' + this.$route.params.api_identifier + '/fields/' + this.selected_for_delete.id)
        .then(() => {
          // Delete noti laten zien
          this.$store.commit('notification/notify', {
            text: 'notification.deleted_success',
            color: 'success',
          })
        })
        .catch(error => {})
        .finally(() => {
          // TODO: Confirmation display? Yes/Cancel
          // Velden opnieuw ophalen.
          this.getFields()
          this.loading = false
          this.delete_item_dialog = false
        })
    },
    updateOrder() {
      this.loading = true
      //check if order is changed and make an update if so
      for (let i = 1; i <= this.contenttype_fields.length; i++) {
        let index = i - 1
        if (i != this.contenttype_fields[index].order_index) {
          this.$http
            .post(
              '/contenttypes/' + this.$route.params.api_identifier + '/fields/' + this.contenttype_fields[index].id,
              { order_index: i },
            )
            // then = een positief (2xx) response
            .then(res => {
              this.contenttype_fields[index].order_index = res.data.order_index
            })
        }
      }
      this.loading = false
    },
    deleteContenttype() {
      this.loading = true
      this.$http
        .delete('/contenttypes/' + this.$route.params.api_identifier)
        .then(() => {
          this.$router.push('/contenttypes/')
          this.$store.commit('notification/notify', {
            text: 'notification.deleted_success',
            color: 'success',
          })
        })

        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style>
.flip-list-move {
  transition: transform 0.5s;
}
.ghost {
  opacity: 0.4;
  background: #c8ebfb;
}
.list-group {
  min-height: 20px;
}
.list-group-item-drag {
  cursor: move;
}
.list-group-item {
  cursor: pointer;
}
</style>