import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('routes.contenttypes.index')))]),_c('Datatable',{attrs:{"get_endpoint":"contenttypes","delete_endpoint":_vm.hasLaravelRoute('contenttypes.delete') ? 'contenttypes' : null,"header_items":_vm.headers,"create_route":"true","create_action":function () {
        _vm.open_navigation_drawer = true
      },"edit_route":_vm.hasVueRoute('contenttypes.edit') ? 'contenttypes.edit' : null,"user_friendly_identifier":"id","api_identifier":"api_identifier","item_on_click":function (item) {
        if (_vm.hasVueRoute('contenttypes.edit')) {
          this$1.$router.push({
            name: 'contenttypes.edit',
            params: { api_identifier: item.api_identifier },
          })
        }
      },"type":"entity.contenttype"}}),_c(VNavigationDrawer,{staticClass:"app-customizer-drawer",attrs:{"width":"400","temporary":"","fixed":"","right":!_vm.$vuetify.rtl},model:{value:(_vm.open_navigation_drawer),callback:function ($$v) {_vm.open_navigation_drawer=$$v},expression:"open_navigation_drawer"}},[_c('div',{staticClass:"app-customizer-header customizer-section py-3"},[_c('h6',{staticClass:"font-weight-semibold text-xl text--primary"},[_vm._v(" "+_vm._s(_vm.$t('table.add_entity', { entity: _vm.$t('entity.contenttype') }))+" ")]),_c(VBtn,{staticClass:"icon-customizer-close",attrs:{"icon":""},on:{"click":function($event){_vm.open_navigation_drawer = false}}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1)],1),_c(VDivider),_c('contenttype-add',{attrs:{"callback":function (api_identifier) {
          _vm.open_navigation_drawer = false
          _vm.$router.push('/contenttypes/edit/' + api_identifier)
        }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }