<template>
  <v-dialog v-model="open" persistent max-width="800">
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <template>
          <template v-if="stepper == 1">
            {{ $t('page.contenttype.add_field') }}
          </template>
          <template v-if="stepper == 2">
            {{ $t('common.new') }} {{ $t('page.contenttype.types.' + contenttype_field.type + '.name') }}
          </template>
          <template v-if="stepper == 3">
            <div>
              {{ $t('page.contenttype.types.' + contenttype_field.type + '.name') }}
              <span class="font-weight-light">: {{ contenttype_field.translations[$i18n.locale].name }}</span>
            </div>
          </template>
          <v-btn icon @click="closeDialog">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </template>
      </v-card-title>
      <v-divider />

      <v-stepper flat v-model="stepper" class="pa-5">
        <v-stepper-items class="pa-0">
          <v-stepper-content class="pa-0 pb-5" step="1">
            <v-row class="" justify="center">
              <v-col
                cols="12"
                sm="6"
                md="3"
                class="d-flex justify-center"
                v-for="fieldtype in fieldtype_options"
                :key="fieldtype.key"
              >
                <v-card
                  @click="() => selectFieldType(fieldtype.key)"
                  elevation="0"
                  class="d-flex flex-column align-center justify-center"
                >
                  <v-card class="d-flex flex-column align-center justify-center pa-2" outlined>
                    <v-icon size="80">{{ fieldtype.icon }}</v-icon>
                  </v-card>
                  <div class="my-2 font-weight-bold">
                    {{ $t('page.contenttype.types.' + fieldtype.key + '.name') }}
                  </div>
                  <v-card-text style="height: 70px" class="pa-0 text-center">
                    {{ $t('page.contenttype.types.' + fieldtype.key + '.description') }}
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-row class="py-1">
              <v-col cols="12" md="6">
                <v-row>
                  <v-col v-for="(lang, i) in langs" :key="i" cols="12">
                    <v-text-field
                      v-model="contenttype_field.translations[lang.key].name"
                      :label="$t('label.name')"
                      outlined
                      hide-details="auto"
                      @input="translationChanges++"
                      v-on:keyup.enter="translationsAreFilled ? (stepper = 3) : ''"
                    >
                      <template v-slot:append>
                        <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
                        <div class="mt-1">{{ lang.native }}</div>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contenttype_field.api_identifier"
                  :error-messages="$t(errors.api_identifier[0], { field: $t('label.field_id') })"
                  :label="$t('label.field_id')"
                  outlined
                  hide-details="auto"
                  v-on:keyup.enter="translationsAreFilled ? (stepper = 3) : ''"
                  @change="errors.api_identifier = ''"
                />
                <div class="py-1">{{ $t('page.contenttype.field_id_description') }}</div>
                <v-alert
                  class="pt-2"
                  width="100%"
                  :value="true"
                  color="warning"
                  :icon="mdiAlert"
                  id="contenttype_field-alert"
                >
                  {{ $t('page.contenttype.field_warning_id') }}
                </v-alert>
              </v-col>
            </v-row>
            <div class="d-flex justify-end mt-2" style="width: 100%">
              <v-btn text @click="stepper = 1">{{ $t('common.back') }}</v-btn>
              <v-btn class="ml-2" :disabled="!translationsAreFilled" color="accent" @click="toFinalStep">{{
                $t('common.continue')
              }}</v-btn>
            </div>
          </v-stepper-content>
          <v-stepper-content step="3" class="pa-0">
            <v-row class="py-1">
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contenttype_field.translations[$i18n.locale].name"
                  disabled
                  :label="$t('label.name')"
                  outlined
                  :prepend-inner-icon="mdiLock"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="contenttype_field.api_identifier"
                  disabled
                  :label="$t('label.field_id')"
                  outlined
                  :prepend-inner-icon="mdiLock"
                  hide-details="auto"
                />
              </v-col>
              <v-col cols="12" class="mt-n5">
                <v-select
                  v-if="contenttype_field.type.includes('option')"
                  v-model="contenttype_field.options_from_contenttype_id"
                  :items="contenttypes"
                  :item-text="item => item.translations[$i18n.locale].name"
                  :error-messages="$t(errors.options_from_contenttype_id, { field: $t('entity.contenttype') })"
                  item-value="id"
                  :label="$t('entity.contenttype')"
                  class="mt-0"
                />
                <v-checkbox
                  v-if="show_is_required"
                  v-model="contenttype_field.is_required"
                  hide-details="auto"
                  :label="$t('page.contenttype.required')"
                  class="mt-1"
                />
                <v-checkbox
                  v-if="show_is_title"
                  v-model="contenttype_field.is_content_title"
                  hide-details="auto"
                  :label="$t('page.contenttype.represents_entry_title')"
                  class="mt-1"
                />
                <template v-if="show_is_translatable">
                  <v-checkbox
                    v-model="contenttype_field.is_translatable"
                    hide-details="auto"
                    :label="$t('page.contenttype.enable_localization')"
                    class="mt-1"
                  />
                  <div class="font-weight-light ml-8">{{ $t('page.contenttype.enable_localization_sub') }}</div>
                  <div class="font-weight-light ml-8 mb-4">
                    <div v-for="lang in $store.getters['i18n/langs']" :key="lang.key">- {{ lang.native }}</div>
                  </div>
                </template>

                <template v-if="contenttype_field.type == 'file'">
                  <v-combobox
                    v-model="settings.file_extentions"
                    :label="$t('page.contenttype.file_extentions')"
                    :hint="$t('page.contenttype.file_extentions_hint')"
                    multiple
                    clearable
                    chips
                  ></v-combobox>
                  <v-checkbox
                    v-model="settings.multiple_files"
                    hide-details="auto"
                    :label="$t('page.contenttype.multiple_files')"
                    class="mt-1"
                  />
                </template>

                <template v-if="contenttype_field.type == 'datetime'">
                  <v-checkbox
                    v-model="settings.include_time"
                    hide-details="auto"
                    :label="$t('page.contenttype.include_time')"
                    class="mt-1"
                  />
                </template>

                <template v-if="contenttype_field.type == 'text' || contenttype_field.type == 'textarea'">
                  <v-checkbox
                    v-model="use_regex"
                    hide-details="auto"
                    :label="$t('page.contenttype.use_regex')"
                    class="mt-1 mb-4"
                  />
                </template>
                <template
                  v-if="(contenttype_field.type == 'text' || contenttype_field.type == 'textarea') && use_regex"
                >
                  <v-text-field
                    v-model="contenttype_field.validation_regular_expression"
                    :label="$t('label.validation_regular_expression')"
                    outlined
                  >
                    <template v-slot:append-outer>
                      <v-btn class="mt-n3" icon @click="regex_suggetion_dialog = true">
                        <v-icon>{{ mdiBookOpenPageVariant }}</v-icon>
                      </v-btn>
                    </template>
                  </v-text-field>

                  <v-row>
                    <v-col v-for="(lang, i) in langs" :key="'regular_expression_error_' + i" cols="12">
                      <v-text-field
                        v-model="contenttype_field.translations[lang.key].regular_expression_error"
                        :label="$t('label.regular_expression_error')"
                        outlined
                        hide-details="auto"
                        @input="translationChanges++"
                      >
                        <template v-slot:append>
                          <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
                          <div class="mt-1">{{ lang.native }}</div>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                </template>
              </v-col>
            </v-row>
            <div class="d-flex justify-end mt-2" style="width: 100%">
              <v-btn :disabled="loading" text @click="stepper = 2">{{ $t('common.back') }}</v-btn>
              <v-btn class="ml-2" :disabled="loading" :loading="loading" color="accent" @click="createField">
                <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
                {{ $t('common.save') }}
              </v-btn>
            </div>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>

    <regex-suggestion-dialog v-model="regex_suggetion_dialog" :selectPattern="selectRegexPattern" />
  </v-dialog>
</template>

<script>
import RegexSuggestionDialog from './RegexSuggestionDialog.vue'
import {
  mdiAlert,
  mdiFormatText,
  mdiTextBox,
  mdiNumeric,
  mdiClipboardTextClock,
  mdiCheckboxOutline,
  mdiCog,
  mdiCogs,
  mdiLock,
  mdiClose,
  mdiBookOpenPageVariant,
  mdiContentSaveOutline,
  mdiFileOutline,
  mdiTextBoxEdit,
} from '@mdi/js'

export default {
  name: 'ContentfieldCreateDialog',
  components: {
    RegexSuggestionDialog,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    refreshParrent: {
      type: Function,
      required: true,
    },
  },
  beforeMount() {
    this.langs = this.$store.getters['i18n/langs']
    this.langs.forEach(lang => {
      this.contenttype_field.translations[lang.key] = {
        name: '',
      }

      this.errors.translations[lang.key] = {
        name: '',
      }
    })

    this.$http('/contenttypes').then(res => {
      this.contenttypes = res.data.data
    })
  },

  data: () => {
    return {
      open: false,
      loading: false,
      regex_suggetion_dialog: false,
      stepper: 1,
      translationChanges: 0,
      use_regex: false,
      contenttype_field: {
        type: '',
        translations: {},
        api_identifier: '',
        is_required: true,
        is_content_title: false,
        is_translatable: false,
        options_from_contenttype_id: undefined,
        validation_regular_expression: undefined,
      },
      errors: {
        translations: {},
        options_from_contenttype_id: '',
        api_identifier: [],
      },

      settings: {
        include_time: false,
        file_extentions: [],
        multiple_files: false,
      },

      contenttypes: [],

      //vars used by the checkboxes to disable certain options
      show_is_required: true,
      show_is_translatable: true,
      show_is_title: true,

      langs: [],
      // $t('page.contenttype.field_warning') TODO: Dit moet vertaald worden!
      contenttype_fieldAlert: "These field can't be changed later!",
      fieldtype_options: [
        {
          key: 'text',
          icon: mdiFormatText,
        },
        {
          key: 'textarea',
          icon: mdiTextBox,
        },
        {
          key: 'richtext',
          icon: mdiTextBoxEdit,
        },
        {
          key: 'number',
          icon: mdiNumeric,
        },
        {
          key: 'datetime',
          icon: mdiClipboardTextClock,
        },
        {
          key: 'boolean',
          icon: mdiCheckboxOutline,
        },
        {
          key: 'options',
          icon: mdiCogs,
        },
        {
          key: 'option',
          icon: mdiCog,
        },
        {
          key: 'file',
          icon: mdiFileOutline,
        },
      ],
      //icons
      mdiFormatText,
      mdiTextBox,
      mdiNumeric,
      mdiClipboardTextClock,
      mdiCheckboxOutline,
      mdiCog,
      mdiCogs,
      mdiAlert,
      mdiLock,
      mdiClose,
      mdiBookOpenPageVariant,
      mdiContentSaveOutline,
      mdiFileOutline,
      mdiTextBoxEdit,
    }
  },

  methods: {
    selectFieldType(keyname) {
      this.configureOptions(keyname)
      this.stepper = 2
    },
    configureOptions(keyname) {
      this.contenttype_field.type = keyname
      this.contenttype_field.is_required = true
      this.contenttype_field.is_content_title = false
      this.contenttype_field.is_translatable = false

      this.show_is_translatable = false
      this.show_is_title = false
      this.show_is_required = true
      switch (keyname) {
        case 'text':
          this.show_is_translatable = true
          this.show_is_title = true
          break
        case 'textarea':
          this.show_is_translatable = true
          break
        case 'richtext':
          this.show_is_translatable = true
          break
        case 'number':
          break
        case 'datetime':
          break
        case 'boolean':
          this.show_is_required = false
          break
        case 'options':
          break
        case 'option':
          break
        case 'file':
          break
      }
    },
    toFinalStep() {
      if (!this.contenttype_field.api_identifier) {
        this.contenttype_field.api_identifier = this.contenttype_field.translations[this.$i18n.locale].name
          .toLowerCase()
          .replace(' ', '_')
      }
      this.stepper = 3
    },
    createField() {
      this.loading = true

      this.contenttype_field.settings = {}
      if (this.settings.include_time) {
        this.contenttype_field.settings.include_time = true
      }
      if (this.settings.file_extentions.length > 0) {
        this.contenttype_field.settings.file_extentions = this.settings.file_extentions
      }
      this.contenttype_field.settings.multiple_files = this.settings.multiple_files

      this.$http
        .post('/contenttypes/' + this.$route.params.api_identifier + '/fields/create', this.contenttype_field)
        .then(res => {
          this.refreshParrent()
          this.open = false
          this.clearModel()
        })
        .catch(error => {
          console.log(error)
          if (typeof error == 'object' && typeof error.response.data.error == 'object') {
            this.errors = error.response.data.error
            if (!this.errors.hasOwnProperty('translations')) {
              this.errors.translations = {}
            }
            this.langs.forEach(lang => {
              if (!this.errors.translations.hasOwnProperty(lang.key)) {
                this.errors.translations[lang.key] = {
                  name: '',
                }
              }
            })
          }

          //Zet de stepper terug zodat de user de eerste error ziet
          if (error.response.data.error.api_identifier) {
            this.stepper = 2
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    clearModel() {
      this.stepper = 1
      this.settings.include_time = false
      this.settings.file_extentions = []
      this.settings.multiple_files = false

      this.contenttype_field = {
        type: '',
        translations: {},
        api_identifier: '',
        is_required: true,
        is_content_title: false,
        is_translatable: false,
        options_from_contenttype_id: undefined,
        validation_regular_expression: undefined,
      }
      this.errors = {
        translations: {},
        options_from_contenttype_id: '',
        api_identifier: [],
      }
      this.langs.forEach(lang => {
        this.contenttype_field.translations[lang.key] = {
          name: '',
        }
        this.errors.translations[lang.key] = {
          name: '',
        }
      })
    },
    closeDialog() {
      this.open = false
      this.stepper = 1
    },
    selectRegexPattern(pattern, translationKey) {
      this.contenttype_field.validation_regular_expression = pattern

      if (translationKey) {
        let translationKeys = translationKey.split('.')
        this.langs.forEach(lang => {
          let msg = this.$i18n.messages[lang.key]
          translationKeys.forEach(key => {
            if (msg instanceof Object && msg.hasOwnProperty(key)) {
              msg = msg[key]
            } else {
              msg = null
            }
          })

          this.contenttype_field.translations[lang.key].regular_expression_error = msg
        })
      }
    },
  },
  computed: {
    translationsAreFilled() {
      // dit loze veld is nodig hier zodat er wel wordt gekeken naar de wijzigingen van de translations, deze zijn er pas na de beforeMount dus anders doet deze niets
      this.translationChanges

      let result = true
      this.langs.forEach(lang => {
        if (
          !this.contenttype_field.translations.hasOwnProperty(lang.key) ||
          this.contenttype_field.translations[lang.key].name == ''
        ) {
          result = false
        }
      })
      return result
    },
  },
  watch: {
    value: {
      handler(newVal) {
        this.open = newVal
      },
    },
    open: {
      handler(newVal) {
        if (!newVal) {
          this.$emit('input', newVal)
        }
      },
    },
    'settings.file_extentions': function (newVal) {
      newVal.forEach(ext => {
        if (ext.match(/\.[0-9a-z]+$/i) == null) {
          this.settings.file_extentions = this.settings.file_extentions.filter(e => e !== ext)
          return
        }
      })
    },
  },
}
</script>