import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"550"},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c(VCard,{staticClass:"py-8"},[_c(VRow,{attrs:{"no-gutters":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VIcon,{attrs:{"size":"100"}},[_vm._v(_vm._s(_vm.mdiAlertCircleOutline))])],1)],1),_c(VCardTitle,{staticClass:"text-center justify-center text-h5 px-5",staticStyle:{"word-break":"break-word"}},[_c('i18n',{attrs:{"tag":"div","path":"dialog.delete_title"},scopedSlots:_vm._u([{key:"type",fn:function(){return [_c('b',{staticClass:"bold"},[_vm._v(_vm._s(_vm.type.toLowerCase()))])]},proxy:true},{key:"label",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm.identifier_label.toLowerCase()))])]},proxy:true},{key:"value",fn:function(){return [_c('b',[_vm._v(_vm._s(_vm.value_copy))])]},proxy:true}])})],1),_c(VRow,{staticClass:"pa-0 ma-0",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{staticClass:"error",on:{"click":_vm.deleteOnClick}},[_vm._v(_vm._s(_vm.$t('common.delete')))])],1),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(_vm._s(_vm.$t('common.cancel')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }