<template>
  <v-form @submit.prevent>
    <v-row>
      <v-col cols="12" md="9">
        <v-card>
          <v-card-title>
            {{ $t('routes.roles.edit') }}
          </v-card-title>
          <v-divider />
          <v-row class="px-5 pt-5">
            <v-col v-for="(lang, i) in langs" :key="i" cols="12">
              <v-text-field
                v-model="role.translations[lang.key].name"
                :error-messages="$t(errors.translations[lang.key].name, { field: $t('label.name') })"
                :label="$t('label.name')"
                outlined
                hide-details="auto"
              >
                <template v-slot:append>
                  <v-img :src="lang.image" height="14px" width="22px" :alt="lang.key" class="me-2 mt-1"></v-img>
                  <div class="mt-1">{{ lang.native }}</div>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="px-5 pb-5">
            <v-col cols="12">
              <v-select
                v-model="role.assignable_roles"
                :items="available_roles"
                :item-text="item => item.translations[$i18n.locale].name"
                item-value="id"
                :error-messages="$t(errors.assignable_roles, { field: $t('label.role') })"
                hide-details="auto"
                chips
                :label="$t('page.role.roles_to_assign_hint')"
                multiple
                clearable
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="6">
              <v-card-text>
                <div class="text--secondary caption">
                  {{ $t('label.updated_at') }} {{ updated_at }}
                  <template v-if="updated_by"> {{ $t('label.by') }} {{ updated_by }} </template>
                </div>

                <div class="text--secondary caption">
                  {{ $t('label.created_at') }} {{ created_at }}
                  <template v-if="created_by"> {{ $t('label.by') }} {{ created_by }} </template>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card>
          <v-card-text>
            <v-btn
              :disabled="loading"
              :loading="loading"
              color="accent"
              type="submit"
              @click="addRole"
              block
              small
              class="mb-3"
            >
              <v-icon class="mr-1">{{ mdiContentSaveOutline }}</v-icon>
              {{ $t('common.save') }}
            </v-btn>
            <v-btn small block outlined :disabled="loading" @click="$router.push('/roles')">
              <v-icon class="ml-r" small>{{ mdiArrowULeftTop }}</v-icon>
              {{ $t('common.back') }}
            </v-btn>
          </v-card-text>
          <v-divider class="my-1"></v-divider>
          <v-card-text>
            <v-btn
              v-if="hasVueRoute('rights')"
              block
              outlined
              small
              @click="$router.push('/rights')"
              class="mb-3"
              :loading="loading"
              :disabled="loading"
              >{{ $t('common.manage_rights') }}
            </v-btn>
            <delete-button
              :deleteOnClick="deleteOnClick"
              small
              :loading="loading"
              :disabled="loading"
              :type="$t('entity.role')"
              :identifier_label="$t('label.name')"
              :identifier_value="role.translations[$i18n.locale].name"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mdiArrowULeftTop, mdiContentSaveOutline } from '@mdi/js'
import DeleteButton from '@/components/DeleteButton.vue'

export default {
  name: 'RoleEdit',
  components: { DeleteButton },
  beforeMount() {
    this.langs = this.$store.getters['i18n/langs']
    this.langs.forEach(lang => {
      if (!this.role.translations.hasOwnProperty(lang.key)) {
        this.role.translations[lang.key] = {
          name: '',
        }
      }
      if (!this.errors.translations.hasOwnProperty(lang.key)) {
        this.errors.translations[lang.key] = {
          name: '',
        }
      }
    })

    this.$http('roles/' + this.$route.params.id)
      .then(res => {
        this.role = res.data
        if (!this.errors.hasOwnProperty('translations')) {
          this.errors.translations = {}
        }
        this.langs.forEach(lang => {
          if (!this.role.translations.hasOwnProperty(lang.key)) {
            this.role.translations[lang.key] = {
              name: '',
            }
          }
          if (!this.errors.translations.hasOwnProperty(lang.key)) {
            this.errors.translations[lang.key] = {
              name: '',
            }
          }
        })
        if (!this.role.hasOwnProperty('assignable_roles')) {
          this.role.assignable_roles = []
        }
        // TODO, als je hier niet wat doet, dan gaat het opslaan obv object, ipv id van het item
        // this.role.assignable_roles = [];
        // if(res.data.hasOwnProperty('assignable_roles')) {
        //   res.data.assignable_roles.forEach(function(assignable_role) {
        //     this.role.assignable_roles.push(assignable_role.id);
        //   });
        // }

        if (this.role.created_by) {
          this.created_by =
            this.role.created_by.firstname +
            ' ' +
            (this.role.created_by.middlename || '') +
            ' ' +
            this.role.created_by.lastname
        }
        if (this.role.updated_by) {
          this.updated_by =
            this.role.updated_by.firstname +
            ' ' +
            (this.role.updated_by.middlename || '') +
            ' ' +
            this.role.updated_by.lastname
        }
      })
      .catch(err => {
        console.log(err)
        this.$router.push('/roles')
      })

    this.$http('roles/assignable/index').then(res => {
      this.available_roles = res.data
    })
  },
  data() {
    return {
      id: null,
      loading: false,
      langs: [],
      available_roles: [],
      role: {
        translations: {},
        assignable_roles: [],
      },
      errors: {
        translations: {},
      },

      updated_at: '',
      created_at: '',
      updated_by: '',
      created_by: '',

      mdiContentSaveOutline,
      mdiArrowULeftTop,
    }
  },
  methods: {
    addRole() {
      this.loading = true
      this.$http
        .post('roles/' + this.$route.params.id, this.role)
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.saved',
          })
        })
        .catch(error => {
          if (typeof error == 'object' && typeof error.response.data.error == 'object') {
            this.errors = error.response.data.error
            if (!this.errors.hasOwnProperty('translations')) {
              this.errors.translations = {}
            }
            this.langs.forEach(lang => {
              if (!this.errors.translations.hasOwnProperty(lang.key)) {
                this.errors.translations[lang.key] = {
                  name: '',
                }
              }
            })
          }
        })
        .finally(() => {
          this.loading = false
          document.activeElement.blur()
        })
    },
    deleteOnClick() {
      this.loading = true
      this.$http
        .delete('roles/' + this.$route.params.id)
        .then(() => {
          this.$store.commit('notification/notify', {
            text: 'notification.deleted_success',
            color: 'success',
          })
          this.$router.push('/roles')
        })
        .finally(() => {
          this.loading = false
        })
    },
    isAssignableByCurrentRole(role) {
      let result = false
      this.available_roles.forEach(available_role => {
        if (available_role.id == role.id) {
          result = true
        }
      })
      return result
    },
  },
}
</script>

<style scoped>
.XGutter * {
  padding-right: 8px;
}
</style>
