import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{on:{"submit":function($event){$event.preventDefault();}}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"9"}},[_c(VCard,[_c(VCardTitle,[_vm._v(_vm._s(_vm.$t('page.contenttype_entries.add_entry_title', { type: _vm.getContenttypeName, })))]),_c(VDivider),(_vm.contenttype_fields == 0)?_c('div',{staticClass:"pa-5"},[_vm._v(_vm._s(_vm.$t('page.contenttype_entries.no_fields')))]):_vm._e(),_vm._l((_vm.contenttype_fields),function(field,index){return _c('contententry-input',{key:index,model:{value:(_vm.contenttype_fields[index]),callback:function ($$v) {_vm.$set(_vm.contenttype_fields, index, $$v)},expression:"contenttype_fields[index]"}})})],2)],1),_c(VCol,{attrs:{"cols":"12","md":"3"}},[_c(VCard,[_c(VCardText,[_c(VBtn,{staticClass:"mb-3",attrs:{"disabled":_vm.loading,"loading":_vm.loading,"color":"accent","type":"submit","block":"","small":""},on:{"click":_vm.addContenttypeEntry}},[_c(VIcon,{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.mdiContentSaveOutline))]),_vm._v(" "+_vm._s(_vm.$t('common.save'))+" ")],1),_c(VBtn,{attrs:{"small":"","block":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$router.push('/contenttype/entries')}}},[_c(VIcon,{staticClass:"ml-r",attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiArrowULeftTop))]),_vm._v(" "+_vm._s(_vm.$t('common.back'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }