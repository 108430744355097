import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"22","min-width":"175","left":"","elevation":_vm.$vuetify.theme.dark ? 9 : 8},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center"},'div',attrs,false),on),[_c(VImg,{staticClass:"me-2",attrs:{"src":_vm.langs[_vm.selected_index].image,"alt":_vm.langs[_vm.selected_index].key,"height":"14px","width":"22px"}}),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v(_vm._s(_vm.langs[_vm.selected_index].native))]):_vm._e()],1)]}}])},[_c(VList,[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.selected_index),callback:function ($$v) {_vm.selected_index=$$v},expression:"selected_index"}},_vm._l((_vm.langs),function(lang,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.selectLanguage(lang)}}},[_c(VImg,{staticClass:"me-2",attrs:{"src":lang.image,"height":"14px","width":"22px","alt":lang.key}}),_c(VListItemTitle,[_vm._v(_vm._s(lang.native))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }