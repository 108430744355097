import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.local_roles.length > 1)?_c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"22","min-width":"175","left":"","elevation":_vm.$vuetify.theme.dark ? 9 : 8},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VDivider,{staticClass:"my-2"}),_c('div',_vm._g(_vm._b({staticClass:"mx-5",attrs:{"text":""}},'div',attrs,false),on),[_c('span',{staticClass:"caption mr-1"},[_vm._v(_vm._s(_vm.$t('label.active_role'))+":")]),_vm._v(" "+_vm._s(_vm.local_roles[_vm.selected_index].translations[_vm.$i18n.locale] ? _vm.local_roles[_vm.selected_index].translations[_vm.$i18n.locale].name : '')+" "),_c(VIcon,[_vm._v(_vm._s(_vm.mdiChevronDown))])],1)]}}],null,false,2684305906)},[_c(VList,[_c(VListItemGroup,{attrs:{"mandatory":""},model:{value:(_vm.selected_index),callback:function ($$v) {_vm.selected_index=$$v},expression:"selected_index"}},_vm._l((_vm.local_roles),function(role,i){return _c(VListItem,{key:i,on:{"click":function($event){return _vm.selectRole(role)}}},[_c(VListItemTitle,[_vm._v(_vm._s(role.translations[_vm.$i18n.locale].name))])],1)}),1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }