<template>
  <div>
    <div class="rich-text-editor">
      <v-row align-content="center" align="center" no-gutters>
        <v-col class="d-flex">
          <v-btn-toggle :value="text_styling" color="primary" borderless multiple dense v-if="editor">
            <v-btn @click="editor.chain().focus().toggleBold().run()" text>
              <v-icon>{{ mdiFormatBold }}</v-icon>
            </v-btn>
            <v-btn @click="editor.chain().focus().toggleItalic().run()" text>
              <v-icon>{{ mdiFormatItalic }}</v-icon>
            </v-btn>
            <v-btn @click="editor.chain().focus().toggleUnderline().run()" text>
              <v-icon>{{ mdiFormatUnderline }}</v-icon>
            </v-btn>
            <v-btn @click="editor.chain().focus().toggleStrike().run()" text>
              <v-icon>{{ mdiFormatStrikethrough }}</v-icon>
            </v-btn>
            <v-btn @click="editor.chain().focus().toggleCodeBlock().run()" text>
              <v-icon>{{ mdiCodeBraces }}</v-icon>
            </v-btn>
            <v-btn @click="editor.chain().focus().toggleBulletList().run()" text>
              <v-icon>{{ mdiFormatListBulletedSquare }}</v-icon>
            </v-btn>
            <v-btn @click="setLink" text class="no-active">
              <v-icon>{{ mdiLinkVariant }}</v-icon>
            </v-btn>
            <v-btn @click="selectImage" text>
              <v-icon>{{ mdiImage }}</v-icon>
            </v-btn>
          </v-btn-toggle>
          <div class="ml-4" style="align-self: center" v-if="title != ''">
            {{ title }}
          </div>
          <div class="ml-4" style="align-self: center" v-if="title != ''">
            <v-img :src="language_image" height="14px" width="22px" class="me-2 mt-1"></v-img>
          </div>
        </v-col>

        <v-col cols="auto">
          <v-item-group group v-if="editor">
            <v-btn @click="editor.chain().focus().undo().run()" text>
              <v-icon>{{ mdiUndo }}</v-icon>
            </v-btn>
            <v-btn @click="editor.chain().focus().redo().run()" text>
              <v-icon>{{ mdiRedo }}</v-icon>
            </v-btn>
          </v-item-group>
        </v-col>
      </v-row>
    </div>

    <editor-content :editor="editor" />
    <input ref="uploader" type="file" class="d-none" @change="inputChanged" accept="image/*" />
  </div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'

import {
  mdiFormatBold,
  mdiFormatItalic,
  mdiFormatUnderline,
  mdiFormatStrikethrough,
  mdiCodeBraces,
  mdiFormatListBulletedSquare,
  mdiImage,
  mdiUndo,
  mdiRedo,
  mdiLinkVariant,
} from '@mdi/js'

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    language_image: {
      type: String,
      default: '',
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        StarterKit,
        Underline,
        Link,
        Image.configure({
          allowBase64: true,
          inline: true,
        }),
      ],
      onUpdate: () => {
        // HTML
        this.$emit('input', this.editor.getHTML())

        // JSON
        // this.$emit('input', this.editor.getJSON())
      },
    })
  },

  beforeDestroy() {
    this.editor.destroy()
  },

  data() {
    return {
      editor: null,
      dragover: false,
      uploaded_files: [],
      image_upload_dialog: false,

      mdiFormatBold,
      mdiFormatItalic,
      mdiFormatUnderline,
      mdiFormatStrikethrough,
      mdiCodeBraces,
      mdiFormatListBulletedSquare,
      mdiImage,
      mdiUndo,
      mdiRedo,
      mdiLinkVariant,
    }
  },
  methods: {
    closeDialog() {
      this.uploaded_files = []
      this.image_upload_dialog = false
    },

    selectImage() {
      this.$refs.uploader.click()
    },

    inputChanged() {
      if (this.$refs.uploader.files[0]) {
        // this.editor.commands.setImage({src: })
        var reader = new FileReader()

        reader.readAsDataURL(this.$refs.uploader.files[0])
        reader.onload = () => {
          console.log()
          this.editor.commands.setImage({ src: reader.result })
        }
      }
    },

    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      // update link
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
    },
  },

  computed: {
    text_styling() {
      let result = []
      const text_styles = ['bold', 'italic', 'underline', 'strike', 'codeBlock', 'bulletList']
      for (var i = 0; i < text_styles.length; i++) {
        let style = text_styles[i]
        if (this.editor.isActive(style)) {
          result.push(i)
        }
      }
      return result
    },
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value

      // JSON
      // const isSame = JSON.stringify(this.editor.getJSON()) === JSON.stringify(value)

      if (isSame) {
        return
      }

      this.editor.commands.setContent(value, false)
    },
  },
}
</script>

<style lang="scss">
/* Basic editor styles */
.rich-text-editor {
  border: 1px solid rgba(94, 86, 105, 0.14);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ProseMirror:focus {
  outline: none;
}

.ProseMirror {
  border: 1px solid rgba(94, 86, 105, 0.14);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: none;

  padding: 12px;
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }
  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;

    &.ProseMirror-selectednode {
      outline: 3px solid var(--v-primary-base);
    }
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

}
</style>