<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner">
      <v-card class="auth-card">
        <v-card-title class="d-flex align-center justify-center py-5">
          <router-link to="/" class="d-flex align-center">
            <v-img class="mb-2" max-width="150" contain :src="appLogo"></v-img>
          </router-link>
        </v-card-title>
        <v-row justify="center" v-if="loading" class="mb-6">
          <v-col cols="auto">
            <v-progress-circular color="primary" indeterminate size="150"></v-progress-circular>
          </v-col>
        </v-row>
        <template v-else>
          <v-card-text>
            <p class="text-2xl font-weight-semibold text--primary mb-2">
              {{ $t('page.two_factor_reset.title') }}
            </p>
          </v-card-text>

          <!-- login form -->
          <v-card-text>
            <v-btn block class="accent" @click="$router.push('/twofactor/activate')">
              {{ $t('page.two_factor_reset.create_new') }}
            </v-btn>
          </v-card-text>
        </template>
      </v-card>
    </div>

    <!-- background triangle shape  -->
    <img
      class="auth-mask-bg"
      height="190"
      :src="require(`@/assets/images/misc/mask-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
    />
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import themeConfig from '@themeConfig'

export default {
  name: 'TwoFactorReset',
  beforeMount() {
    this.reset_token = new URLSearchParams(window.location.search).get('reset_token')
    this.id = new URLSearchParams(window.location.search).get('id')
    if (this.reset_token && this.id) {
      this.$http
        .post('/2fa/reset', {
          reset_token: this.reset_token,
          id: this.id,
        })
        .catch(e => {
          this.$store.commit('user/logout')
          this.$router.push('/login')
        })
        .finally(() => {
          this.loading = false
        })
    } else {
      this.loading = false
    }
  },
  data: () => ({
    loading: true,
    reset_token: null,
    id: null,

    appName: themeConfig.app.name,
    appLogo: themeConfig.app.logo,
    arrowLeftIcon: mdiChevronLeft,
  }),
  methods: {
    createPasswordOnClick: function () {
      this.loading = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

